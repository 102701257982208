import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { Button } from "@material-ui/core";
import moment from "moment";
import { submitEnrollment } from "../../api/enrollmentApi";
import updateEnrollData from "../../state/actions/updateEnrollData";
import axios from "axios";
import { browserName, browserVersion } from "react-device-detect";
import { getTelemetry } from "../../utility/telemetry";

const ReviewPaymentChange = ({
  //values,
  handleNext,
  handlePrevious,
  handleJumpTo,
  activeStep,
  acceptTerms,
  steps,
  diffAddress,
  preparer,
  //setFormValues,
  setBackButtonDisabled,
}) => {
  let p1 = activeStep === 0 ? true : false;

  let submitDateTime = "";

  const getIpAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");

    let ip = JSON.stringify(res.data.IPv4);

    // console.log(ip);

    return ip;
  };

  const { state, actions } = useStateMachine({ updateEnrollData });

  console.log("Form data: " + JSON.stringify(state.enrollData));

  const [isProcessing, setIsProcessing] = useState(false);

  const handleNextClick = async () => {
    setIsProcessing(true);
    setBackButtonDisabled(true);

    submitDateTime = moment().format("MM/DD/YYYY h:mm:ss a");

    let f = state.enrollData;

    // console.log("Data: " + JSON.stringify(f));

    f.dor = submitDateTime;
    f.timestamp = submitDateTime;
    f.ipaddress = ""; //await getIpAddress();
    f.browsername = browserName;
    f.browserversion = browserVersion;
    f.telemetry = getTelemetry();

    let s = await submitEnrollment(f);

    let o = JSON.parse(s);

    // console.log("Submit enrollment success: " + JSON.stringify(s));
    // console.log("Confirmation: " + o.confirmationno);

    if (s.confirmationno !== "") {
      actions.updateEnrollData({ confirmationno: o.confirmationno });
      handleNext();
    } else {
    }

    setIsProcessing(false);
  };

  const getPaymentOptionText = () => {
    switch (state.enrollData.paymentoption) {
      case "Paper":
        return "Get a paper bill";
      case "EFT":
        return "Electronic funds transfer (EFT)";
      case "SSA":
        return "Automatic deduction from your Social Security benefit check";
      case "RRB":
        return "Automatic deduction from your Railroad Retirement Board (RRB) benefit check";
      default:
        return "";
    }
  };

  // console.log("API URL: " + process.env.REACT_APP_ENROLLMENT_API_URL);

  return (
    <div>
      <div
        className="
                  field
                  field--name-webform
                  field--type-webform
                  field--label-hidden
                  field__item
                "
      >
        <div
          className="
                    webform-submission-form
                    webform-submission-add-form
                    webform-submission-mbrx-enroll-2021-form
                    webform-submission-mbrx-enroll-2021-add-form
                    webform-submission-mbrx-enroll-2021-node-234-form
                    webform-submission-mbrx-enroll-2021-node-234-add-form
                    js-webform-disable-autosubmit js-webform-details-toggle
                    webform-details-toggle
                  "
          data-drupal-selector="webform-submission-mbrx-enroll-2021-node-234-add-form"
          data-msg-required="This field is required."
          action="/mbrx-enroll-2021"
          method="post"
          id="webform-submission-mbrx-enroll-2021-node-234-add-form"
          acceptCharset="UTF-8"
          noValidate="noValidate"
          data-drupal-form-fields="edit-step-1,edit-step-2,edit-step-3,edit-step-4,edit-agree-terms-enroll-auth,edit-datesigned,edit-signaturefirst,edit-signaturelast,edit-preparer-enrollee,edit-preparer-auth-rep,edit-preparer-assisted,edit-preparer-agent,edit-callcenter,edit-assistorsigned,edit-assistorrelationship2applicant,edit-assistorsigneddate,edit-agentfirst,edit-agentlast,edit-agentid,edit-enrollee-perm-state,edit-agencyid,edit-agentphone,edit-callcentername,edit-callcenterrep,edit-ridnbr,edit-please-specify-one-present,edit-please-specify-one-attestation,edit-datesignedpaper,edit-agentsigned,edit-has-authrep-info,edit-authrepfirstname,edit-authreplastname,edit-authreprelationship,edit-authrepstreet,edit-authrepcity,edit-authrepstate,edit-authrepzip,edit-authrepphone,edit-authrepmail,edit-accessibleformatoption-braille,edit-accessibleformatoption-large-print,edit-accessibleformatoption-audio-cd,edit-actions-wizard-prev,edit-actions-wizard-next"
        >
          <div
            data-webform-key="step_5"
            data-drupal-selector="edit-step-5"
            className="js-form-wrapper form-wrapper form-group"
            data-msg-required="Step 5 is required."
            id="edit-step-5--2"
          >
            <div
              id="edit-step-5-title"
              className="
                        form-item
                        js-form-item
                        form-type-processed-text
                        js-form-type-processed-text
                        form-item-
                        js-form-item-
                        form-no-label form-group
                      "
            >
              <h2>Review Payment Option Change</h2>
            </div>

            <div>
              <div
                className="dialog-off-canvas-main-canvas"
                data-off-canvas-main-canvas=""
              >
                <div
                  role="main"
                  className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
                ></div>
                <div className="row d-md-flex">
                  <section className="col-sm-12 internal-page">
                    <div className="region region-content">
                      <article className="webform full clearfix">
                        <div className="content">
                          <div>
                            <p className="toggle2">
                              S5743_090920NN02_C
                              <br />
                            </p>
                          </div>
                        </div>

                        <div
                          className="
                        webform-submission-form
                        webform-submission-add-form                        
                        webform-details-toggle
                      "
                        >
                          <div className="js-form-wrapper form-wrapper form-group">
                            <div
                              className="
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                            >
                              {/*Row 1*/}
                              <div
                                className="row form-group js-form-wrapper form-wrapper"
                                data-drupal-selector="edit-row-1-container"
                                data-msg-required="This field is required."
                                id="edit-row-1-container"
                              >
                                {/*Personal Information*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Personal information
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    //formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(0);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <div
                                      {...(state.enrollData.sal === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Title:
                                      </label>{" "}
                                      {state.enrollData.sal}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.firstname ===
                                        "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        First name:{" "}
                                      </label>{" "}
                                      {state.enrollData.firstname}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.mi === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Middle initial:
                                      </label>{" "}
                                      {state.enrollData.mi}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.lastname === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Last name:
                                      </label>{" "}
                                      {state.enrollData.lastname}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.dob === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Date of birth:
                                      </label>{" "}
                                      {state.enrollData.dob}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.sex === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Gender:
                                      </label>{" "}
                                      {state.enrollData.sex}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.permst1 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Address:
                                      </label>{" "}
                                      {state.enrollData.permst1}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.permst2 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Address 2
                                      </label>{" "}
                                      {state.enrollData.permst2}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.permcity === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        City:
                                      </label>{" "}
                                      {state.enrollData.permcity}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.permstate ===
                                        "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        State:
                                      </label>{" "}
                                      {state.enrollData.permstate}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.permzip5 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Zip code (xxxxx):
                                      </label>{" "}
                                      {state.enrollData.permzip5}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.permzip4 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Zip+4 (xxxx)
                                      </label>{" "}
                                      {state.enrollData.permzip4}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.homephone ===
                                        "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Home phone:
                                      </label>{" "}
                                      {state.enrollData.homephone}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.altphone === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Alternate phone:
                                      </label>{" "}
                                      {state.enrollData.altphone}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.email === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Email address
                                      </label>{" "}
                                      {state.enrollData.email}
                                      <br />
                                    </div>
                                    <div
                                      {...(!diffAddress && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <div
                                        {...(state.enrollData.mailst1 ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Mailing Address:
                                        </label>{" "}
                                        {state.enrollData.mailst1}
                                        <br />
                                      </div>
                                      <div
                                        {...(state.enrollData.mailst2 ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Mailing Address 2:
                                        </label>{" "}
                                        {state.enrollData.mailst2}
                                        <br />
                                      </div>
                                      <div
                                        {...(state.enrollData.mailcity ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Mailing City:
                                        </label>{" "}
                                        {state.enrollData.mailcity}
                                        <br />
                                      </div>
                                      <div
                                        {...(state.enrollData.mailstate ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Mailing State:
                                        </label>{" "}
                                        {state.enrollData.mailstate}
                                        <br />
                                      </div>
                                      <div
                                        {...(state.enrollData.mailzip5 ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Mailing Zip Code (xxxxx):
                                        </label>{" "}
                                        {state.enrollData.mailzip5}
                                        <br />
                                      </div>
                                      <div
                                        {...(state.enrollData.mailzip4 ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Mailing Zip+4:
                                        </label>{" "}
                                        {state.enrollData.mailzip4}
                                        <br />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/*Medicare Information*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Medicare information
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    //formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(0);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <label className="control-label">
                                      MedicareBlue Rx member number (803xxxxxx):{" "}
                                    </label>{" "}
                                    {state.enrollData.memberidnumber}
                                  </div>
                                </div>
                              </div>

                              {/*Row 2*/}
                              <div
                                className="row form-group js-form-wrapper form-wrapper"
                                data-drupal-selector="edit-row-1-container"
                                data-msg-required="This field is required."
                                id="edit-row-1-container"
                              >
                                {/*Premium Payment Option*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Premium payment option
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(0);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <label className="control-label">
                                      Payment options:{" "}
                                    </label>{" "}
                                    {getPaymentOptionText()}
                                    <br />
                                    <div
                                      {...(state.enrollData.paymentoption !==
                                        "EFT" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Account holder name:
                                      </label>{" "}
                                      {state.enrollData.accountholdername}
                                      <br />
                                      <label className="control-label">
                                        Bank name:
                                      </label>{" "}
                                      {state.enrollData.bankname}
                                      <br />
                                      <label className="control-label">
                                        Account type:
                                      </label>{" "}
                                      {state.enrollData.accttype}
                                      <br />
                                      <label className="control-label">
                                        Bank routing number:
                                      </label>{" "}
                                      {state.enrollData.bankroutingnumber}
                                      <br />
                                      <label className="control-label">
                                        Bank account number:
                                      </label>{" "}
                                      {state.enrollData.bankacctnumber}
                                      <br />
                                    </div>
                                  </div>
                                </div>

                                {/*Applicant/Auth Rep Signature*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Applicant or authorized representative
                                      signature
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(0);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <div>
                                      <label className="control-label">
                                        Enrollee first name
                                      </label>{" "}
                                      {state.enrollData.signaturefirst}
                                      <br />
                                    </div>

                                    <div>
                                      <label className="control-label">
                                        Enrollee last name:
                                      </label>{" "}
                                      {state.enrollData.signaturelast}
                                      <br />
                                    </div>

                                    <div>
                                      <label className="control-label">
                                        Today's date:
                                      </label>{" "}
                                      {moment().format("MM/DD/YYYY")}
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <Button
                                className="
                                  webform-button--previous
                                  button
                                  js-form-submit
                                  form-submit
                                  btn-default btn"
                                onClick={handlePrevious}
                                {...(p1 && {
                                  style: { display: "none" },
                                })}
                              >
                                Previous
                              </Button>

                              <Button
                                className="
                                  webform-button--next
                                  button
                                  js-form-submit
                                  form-submit
                                  btn-default btn"
                                onClick={handleNextClick}
                                disabled={isProcessing}
                                {...(isProcessing && {
                                  style: { color: "black" },
                                })}
                              >
                                {isProcessing ? (
                                  <div>
                                    <span
                                      className="spinner-border pmd-spinner spinner-border-sm text-light mr-2"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    Processing...
                                  </div>
                                ) : (
                                  "Send Now"
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewPaymentChange;
