  export function getGenderText (code) {
    switch (code) {
      case "1":
        return "Woman";
      case "2":
        return "Man";
      case "3":
        return "Non-Binary";
      case "4":
        return "I use a different term";
      case "5":
          return "I choose not to answer";
      default:
        return "";
    }
  };

  export function getSexualOrientationText (code) {
    switch (code) {
      case "1":
        return "Lesbian or gay";
      case "2":
        return "Straight, that is, not gay or lesbian";
      case "3":
        return "Bisexual";
      case "4":
        return "I use a different term";
      case "5":
          return "I do not know";
      case "6":
            return "I choose not to answer";
      default:
        return "";
    }
  };

  export function isAgentDataPopulated (state) {
    return state.enrollData.agentfirst.length > 0
            || state.enrollData.agentlast.length > 0
            || state.enrollData.agentid.length > 0
            || state.enrollData.agencyid.length > 0
            || state.enrollData.agentphone.length > 0
  }