export default function clearContactData(state, payload) {
  return {
    ...state,
    contactData: {
      Sid: "",
      Request_Type: "",
      RcptDate_and_time: "",
      ConfNumber: "",
      FirstName: "",
      LastName: "",
      Address1: "",
      Address2: "",
      City: "",
      StateOfResidence: "",
      Zip: "",
      StateOfDirectory: "",
      PhoneNumber: "",
      MemberIDNumber: "",
      DateOfBirth: "",
      EmailAddress: "",
      message: "",
      DirectoryPlanYear: "",
      EvidenceOfCoverage: "",
      ipaddress: "",
      timestamp: "",
      browsername: "",
      browserversion: "",
      telemetry: ""
    }
  };
}
