import React, { useState, useEffect, useCallback } from "react";
import enrollData from "../../../state/data/enrollData";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  makeStyles,
} from "@material-ui/core";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import Step6 from "./Steps/Step6";
import ReviewEnrollment from "./Steps/ReviewEnrollment";
import EnrollmentConfirmation from "./Steps/EnrollmentConfirmation";
import moment from "moment";
import { useStateMachine } from "little-state-machine";
import updateEnrollData from "../../../state/actions/updateEnrollData";
import clearEnrollData from "../../../state/actions/clearEnrollData";
import { withRouter } from "react-router-dom";
import { ContactsOutlined } from "@material-ui/icons";

const MBRx2025 = ({
  history,
  // counter,
  // setCounter,
  // displayBankValidationMessage,
  // setDisplayBankValidationMessage,
  // disableEFTOption,
  // setDisableEFTOption,
}) => {
  //console.log("Public URL: " + process.env.REACT_APP_PUBLIC_URL);

  const { state, actions } = useStateMachine({
    updateEnrollData,
    clearEnrollData,
  });

  const [backButtonDisabled, setBackButtonDisabled] = useState(false);

  const useStyles = makeStyles((theme) => ({
    iconContainer: {
      // define styles for icon container
      transform: "scale(2)",
    },
    alternativeLabel: {
      fontSize: "16px",
    },
  }));

  const classes = useStyles();

  //const [activeStep, setActiveStep] = useState(0);
  //const [formValues, setFormValues] = useState(enrollData);

  const handleNext = () => {
    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    actions.updateEnrollData({
      activeStep: state.enrollData.activeStep + 1,
    });
    history.push(window.location.pathname);
    //history.push('/mbrx-enroll-2025');
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    //setActiveStep((prevActiveStep) => prevActiveStep - 1);
    actions.updateEnrollData({
      activeStep: state.enrollData.activeStep - 1,
    });
    history.push(window.location.pathname);
    //history.push('/mbrx-enroll-2025');
    window.scrollTo(0, 0);
  };

  const handleJumpTo = (stepIndex) => {
    //setActiveStep(stepIndex);
    actions.updateEnrollData({
      activeStep: stepIndex,
    });
    history.push(window.location.pathname);
    //history.push('/mbrx-enroll-2025');
    window.scrollTo(0, 0);
  };

  const handleChange = (input) => (e) => {
    actions.updateEnrollData({ [input]: e.target.value });
  };

  const formName = "Enroll2025";

  const setDefaultValues = useCallback(() => {
    let a =
      state.enrollData.activeStep === undefined
        ? 0
        : state.enrollData.activeStep;

    if (
      state.enrollData.activeForm !== formName ||
      (state.enrollData.activeForm === formName &&
        state.enrollData.activeStep === 7)
    ) {
      actions.clearEnrollData();
      a = 0;
    }

    actions.updateEnrollData({
      recversion: "2025-01",
      transactiontype: "ENROLL",
      signature: "Y",
      datesigned: moment().format("MM/DD/YYYY"),
      confirmationno: "6NG695", //<--remove after API is wired in
      activeStep: a,
      activeForm: formName,
    });
  }, [state]);

  function getSteps() {
    return [
      "Step 1",
      "Step 2",
      "Step 3",
      "Step 4",
      "Step 5",
      "Step 6",
      "Review Enrollment",
      "Enrollment Confirmation",
    ];
  }

  const steps = getSteps();

  useEffect(() => {       
    if (state.enrollData.activeForm === formName &&
      state.enrollData.activeStep === 7) {
        // Redirect to Home Page on Reload button
        history.push('/'); 
      }
    else {
      setDefaultValues();
    }
  }, []);

  // clear Enroll Data if user navigates away after clicking Send Now button
  useEffect(() => () => {
    if (backButtonDisabled) {
      actions.clearEnrollData();
      actions.updateEnrollData({ activeStep: 0, activeForm: "" });
    }
  }, []);

  //const [birthDate, setBirthDate] = useState(null);

  ///////////////////////////////////
  // Handle Back and Forward buttons
  ///////////////////////////////////
  const [locationHistory, setLocationHistory] = useState({});
  //const [locationKeys, setLocationKeys] = useState([]);

  useEffect(() => {

    if (history.action === 'PUSH') {

      setLocationHistory(locationHistory => ({ ...locationHistory, [history.location.key]: state.enrollData.activeStep }))
      //setLocationKeys([history.location.key, ...locationKeys]);           

    }
    else if (history.action === "POP") {

      if (!backButtonDisabled) {

        var stepNumber = ((history.location.key) ? locationHistory[history.location.key] : 0) ?? 0;

        if (stepNumber >= 0) {
          actions.updateEnrollData({
            activeStep: stepNumber,
          });

        }      

      }

      window.scrollTo(0, 0);

      ////////////////////////////////////////////////////////////////
      // handle Back and Forward buttons (alternative implementation)
      ////////////////////////////////////////////////////////////////
      // if (locationKeys[1] === history.location.key)
      // {
      //   // Handle back event

      //   //setLocationKeys(([_, ...keys]) => keys);

      //   console.log('back button');

      // if (state.enrollData.activeStep > 0) {
      //   actions.updateEnrollData({
      //     activeStep: state.enrollData.activeStep - 1,
      //   });
      // }

      // }
      // else if (locationKeys[0] !== history.location.key)  {
      //   setLocationKeys((keys) => [history.location.key, ...keys]);

      //   // Handle forward event

      //   console.log('forward button');

      //   // //if (state.enrollData.activeStep > 0) {
      //   //   actions.updateEnrollData({
      //   //     activeStep: state.enrollData.activeStep + 1,

      //   //   });
      //   // //}

      // }
    }
  }, [history.location]);

  function getStepsContent(stepIndex) {
    //console.log("stepindex:" + stepIndex);
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            steps={steps}
            //birthDate={birthDate}
            //setBirthDate={setBirthDate}
          />
        );
      case 1:
        return (
          <Step2
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            steps={steps}
          />
        );
      case 2:
        return (
          <Step3
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            steps={steps}
            //counter={counter}
            //setCounter={setCounter}
            //displayBankValidationMessage={displayBankValidationMessage}
            //setDisplayBankValidationMessage={setDisplayBankValidationMessage}
            //disableEFTOption={disableEFTOption}
            //setDisableEFTOption={setDisableEFTOption}
          />
        );
      case 3:
        return (
          <Step4
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            steps={steps}
          />
        );
      case 4:
        return (
          <Step5
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            steps={steps}
          />
        );
      case 5:
        return (
          <Step6
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            steps={steps}            
          />
        );
      case 6:
        return (
          <ReviewEnrollment
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            steps={steps}
            handleJumpTo={handleJumpTo}
            setBackButtonDisabled={setBackButtonDisabled}
          />
        );
      case 7:
        return (
          <EnrollmentConfirmation
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={state.enrollData.activeStep}
            steps={steps}
            setBackButtonDisabled={setBackButtonDisabled}
          />
        );
      default:
        return "Unknown Step";
    }
  }

  let p1 = state.enrollData.activeStep === 0 ? true : false;
  //console.log(p1);

  return (
    <div>
      <div
        role="main"
        className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
      >
        <div className="row d-md-flex"></div>
        <section
          id="block-clearstonebreadcrumbs"
          className="
                  block
                  block-clearstone-updates
                  block-clearstone-breadcrumbs-block
                  clearfix
                "
        >
          <div className="breadcrumb-wrap">
            <div className="region--breadcrumb nst-1">
              <section
                id="block-ttheme-breadcrumbs"
                className="block-system block-system-breadcrumb-block"
              >
                <div role="navigation">
                  <ol className="breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>MBRx Enroll 2025</li>
                  </ol>
                </div>
              </section>
            </div>
          </div>
        </section>

        <Stepper activeStep={state.enrollData.activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                classes={{
                  iconContainer: classes.iconContainer,
                  alternativeLabel: classes.alternativeLabel,
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <div>{getStepsContent(state.enrollData.activeStep)}</div>
      </div>
      <div className="main-container pt-md-5 pb-5 container js-quickedit-main-content">
        <div className="row d-md-flex">
          <section className="col-sm-12 internal-page">
            <div className="region region-content">
              <article className="webform full clearfix">
                <div className="content">
                  <p className="toggle2">
                      S5743_090924_N01_M
                    <br />
                  </p>
                </div>
              </article>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

//////////////////////////////////////////////////////////////
// Wrap component withRouter to have access to history object
//////////////////////////////////////////////////////////////
const MBRx2025WithRouter = withRouter(MBRx2025);

export default MBRx2025WithRouter;
