import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import contactData from "../../state/data/contactData";
import moment from "moment";
import { Button } from "@material-ui/core";
import clearContactData from "../../state/actions/clearContactData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { submitContact } from "../../api/contactApi";
import axios from "axios";
import CSPhoneInput from "../Common/CSPhoneInput";
import { ContactsOutlined } from "@material-ui/icons";
import { browserName, browserVersion } from "react-device-detect";
import { getTelemetry } from "../../utility/telemetry";

const ContactUs = ({ history }) => {
  const {
    register,
    formState: { errors, isValid, isDirty },
    trigger,
    getValues,
    setValue,
    control,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  //let history = useHistory();

  const [formValues, setFormValues] = useState(contactData);
  const [contactType, setContactType] = useState("none");
  const [displayCheckOptionError, setDisplayCheckOptionError] = useState(false);

  const handleContactType = (e) => {
    let s = e.target.value;
    setFormValues(contactData);
    reset(contactData);
    setFormValues({ ...formValues, Request_Type: s });
    setContactType(s);
  };
  // console.log(contactType);

  const handleChange = (input) => (e) => {
    setFormValues({ ...formValues, [input]: e.target.value });
  };

  const handlePlanYearChange = (input) => (e) => {
    setFormValues({
      ...formValues,
      DirectoryPlanYear: e.target.value,
      StateOfDirectory: "",
      EvidenceOfCoverage: "",
    });
    setValue("StateOfDirectory", ""); // sync useForm form validation
    setValue("EvidenceOfCoverage", ""); // sync useForm form validation
    //setValue("StateOfDirectory", "", { shouldValidate: true });
  };

  // console.log("DirectoryPlanYear:" + formValues.DirectoryPlanYear);
  // console.log("StateOfDirectory:" + formValues.StateOfDirectory);
  // console.log("EvidenceOfCoverage:" + formValues.EvidenceOfCoverage);

  // const handlePhoneNumber = (e) => {
  //   setFormValues({ ...formValues, PhoneNumber: e.replace("+1", "") });
  // };

  const getIpAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");

    let ip = JSON.stringify(res.data.IPv4);

    // console.log(ip);

    return res.data.IPv4;
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const [dobError, setDOBError] = useState(false);
  const [birthDate, setBirthDate] = useState(null);

  let bdErr = false;

  const handleSubmit = async () => {
    trigger();

    var radios = document.querySelectorAll('input[type="radio"]');
    var checkedOne = Array.prototype.slice.call(radios).some((x) => x.checked);

    if (isValid && checkedOne) {
      setIsProcessing(true);

      // if (birthDate === null && contactType === "Rx Order ID Card") {
      //   bdErr = true;
      //   setDOBError(true);
      // } else {
      bdErr = false;
      setDOBError(false);
      // }

      console.log("birthDate: " + birthDate + ", contactType: " + contactType);

      // if (formValues.dob.length === 0 && contactType === "Rx Order ID Card") {
      //   setDOBError(true);
      // } else {
      //   setDOBError(false);
      // }

      let f = formValues;

      f.RcptDate_and_time = moment().format("MM/DD/YYYY h:mm:ss a");
      f.timestamp = moment().format("MM/DD/YYYY h:mm:ss a");
      f.ipaddress = ""; //await getIpAddress();
      f.Request_Type = contactType;
      f.browsername = browserName;
      f.browserversion = browserVersion;
      f.telemetry = getTelemetry();

      let s = await submitContact(f);

      let o = JSON.parse(s);

      // console.log("Submit contact success: " + JSON.stringify(s));
      // console.log("Confirmation: " + o.ConfNumber);
      // console.log("Response: " + s);

      // console.log("Is Valid: " + isValid);
      // console.log("Checked: " + checkedOne);

      if (s.ConfNumber !== "") {
        // && checkedOne && bdErr === false) {
        setFormValues({ ...formValues, ConfNumber: o.ConfNumber });
        history.push("/contact-confirmation", { confNum: o.ConfNumber });
      } else if (!checkedOne) {
        setDisplayCheckOptionError(true);
      }
    }
    setIsProcessing(false);
  };

  const formName = "ContactUs";

  const setDefaultValues = useCallback(() => {
    if (formValues.activeForm !== formName) {
      reset(contactData);
    }
  }, [formValues]);

  useEffect(() => {
    setDefaultValues();
  }, []);

  var d = new Date();

  const handleBirthDate = (e) => {
    let date = moment(e).format("YYYY-MM-DD");
    setBirthDate(e);
    setFormValues({ ...formValues, dob: date });
  };

  // console.log("Birthdate: " + formValues.dob);

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-8 col-md-9 internal-page order-md-2">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>CONTACT FORM ONLINE</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>
            <br />
            <article className="webform full clearfix">
              <div className="content">
                <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                  <p>
                    Only current members can use our online request forms. To
                    complete the form, you will need your member ID number. If
                    you don’t know your member ID number or want to order a
                    pharmacy directory or Evidence of Coverage in large-print or
                    braille format, please{" "}
                    <Link to="/Contact/medicareblue-rx">
                      call our customer service team
                    </Link>
                    . Group MedicareBlue Rx members should contact the{" "}
                    <Link to="/employer-group-plans/group-contact-information">
                      group customer service team
                    </Link>
                    .
                  </p>
                  <p>
                  All plan material requests will be mailed to the address we have on file. If you would like your request to be mailed to a different address, please call the Customer Service phone number on the back of your ID card.
                  </p>
                  <p>
                    If you aren’t yet a member and are interested in an
                    individual Medicare plan, please{" "}
                    <Link to="/Contact/medicareblue-rx">
                      contact our Medicare Solutions specialists
                    </Link>
                    . If you are interested in a group Medicare plan, please ask
                    your employer group to contact a{" "}
                    <Link to="/EmployerGroupPlans/PartDPlansEmployerAndUnionGroups">
                      plan representative.
                    </Link>
                  </p>
                </div>

                <div className="field field--name-webform field--type-webform field--label-hidden field__item">
                  <form
                    className="webform-submission-form webform-submission-add-form webform-submission-contact-form webform-submission-contact-add-form webform-submission-contact-node-166-form webform-submission-contact-node-166-add-form js-webform-details-toggle webform-details-toggle"
                    action="contact-form-online.html"
                    method="post"
                    id="webform-submission-contact-node-166-add-form"
                    acceptCharset="UTF-8"
                  >
                    <fieldset
                      className="radios--wrapper fieldgroup form-composite webform-composite-visible-title required js-webform-type-radios webform-type-radios js-form-item form-item js-form-wrapper form-wrapper"
                      id="edit-request-type--wrapper"
                    >
                      <legend>
                        <span className="fieldset-legend js-form-required form-required">
                          I would like to...
                        </span>
                      </legend>
                      <div className="fieldset-wrapper">
                        <div
                          id="edit-request-type"
                          className="js-webform-radios webform-options-display-one-column"
                        >
                          <div className="form-item js-form-item form-type-radio js-form-type-radio form-item-request-type js-form-item-request-type radio">
                            <label
                              htmlFor="edit-request-type-rx-customer-service"
                              className="control-label option"
                            >
                              <input
                                onChange={handleContactType}
                                checked={contactType === "Rx Customer Service"}
                                className="form-radio"
                                type="radio"
                                id="edit-request-type-rx-customer-service"
                                name="request_type"
                                value="Rx Customer Service"
                              />
                              Contact customer service
                            </label>
                          </div>
                          <div className="form-item js-form-item form-type-radio js-form-type-radio form-item-request-type js-form-item-request-type radio">
                            <label
                              htmlFor="edit-request-type-rx-order-id-card"
                              className="control-label option"
                            >
                              <input
                                onChange={handleContactType}
                                checked={contactType === "Rx Order ID Card"}
                                className="form-radio"
                                type="radio"
                                id="edit-request-type-rx-order-id-card"
                                name="request_type"
                                value="Rx Order ID Card"
                              />
                              Order a member ID card
                            </label>
                          </div>
                          <div className="form-item js-form-item form-type-radio js-form-type-radio form-item-request-type js-form-item-request-type radio">
                            <label
                              htmlFor="edit-request-type-rx-pharmacy-directory-request"
                              className="control-label option"
                            >
                              <input
                                onChange={handleContactType}
                                checked={
                                  contactType ===
                                  "Rx Pharmacy Directory Request"
                                }
                                className="form-radio"
                                type="radio"
                                id="edit-request-type-rx-pharmacy-directory-request"
                                name="request_type"
                                value="Rx Pharmacy Directory Request"
                              />
                              Order a pharmacy directory
                            </label>
                          </div>
                          <div className="form-item js-form-item form-type-radio js-form-type-radio form-item-request-type js-form-item-request-type radio">
                            <label
                              htmlFor="edit-request-type-rx-eoc-request"
                              className="control-label option"
                            >
                              <input
                                onChange={handleContactType}
                                checked={contactType === "Rx EOC Request"}
                                className="form-radio"
                                type="radio"
                                id="edit-request-type-rx-eoc-request"
                                name="request_type"
                                value="Rx EOC Request"
                              />
                              Order an Evidence of Coverage (EOC)
                            </label>
                          </div>
                          <div className="form-item js-form-item form-type-radio js-form-type-radio form-item-request-type js-form-item-request-type radio">
                            <label
                              htmlFor="edit-request-type-rx-eoc-request"
                              className="control-label option"
                            >
                              <input
                                onChange={handleContactType}
                                checked={contactType === "Rx Formulary Drug List"}
                                className="form-radio"
                                type="radio"
                                id="edit-request-type-rx-eoc-request"
                                name="request_type"
                                value="Rx Formulary Drug List"
                              />
                              Order a Formulary Drug List
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <div
                      className="hide-panel-heading js-webform-states-hidden form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                      id="edit-field-display"
                      {...(contactType === "none" && {
                        style: { display: "none" },
                      })}
                    >
                      <div className="panel-heading">
                        <div className="panel-title">Field Display</div>
                      </div>

                      <div className="panel-body">
                        <div
                          className="row form-group js-form-wrapper form-wrapper"
                          id="edit-row-1-container"
                        >
                          <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-firstname js-form-item-firstname form-group">
                            <label
                              htmlFor="edit-firstname"
                              className="control-label form-required"
                            >
                              First name
                            </label>

                            <input
                              {...register("FirstName", {
                                required: "First name is required.",
                                maxLength: {
                                  value: 25,
                                  message:
                                    "First name field has a maximum length of 25.",
                                },
                                pattern: {
                                  value: /^[-a-zA-Z0-9 ]*$/,
                                  message:
                                    "Special characters are not allowed.",
                                },
                              })}
                              value={formValues.FirstName}
                              onChange={handleChange("FirstName")}
                              pattern="^[-a-zA-Z0-9 ]*$"
                              className="form-text required form-control"
                              data-msg-maxlength="First name field has a maximum length of 25."
                              data-msg-required="First name is required."
                              data-msg-pattern="Special characters are not allowed."
                              type="text"
                              id="edit-firstname"
                              size="60"
                              maxLength="25"
                              required="required"
                              aria-required="true"
                            />
                            <div className="error">
                              <ErrorMessage errors={errors} name="FirstName">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-lastname js-form-item-lastname form-group">
                            <label
                              htmlFor="edit-LastName"
                              className="control-label form-required"
                            >
                              Last name
                            </label>

                            <input
                              {...register("LastName", {
                                required: "Last name is required.",
                                maxLength: {
                                  value: 25,
                                  message:
                                    "Last name field has a maximum length of 25.",
                                },
                                pattern: {
                                  value: /^[-a-zA-Z0-9 ]*$/,
                                  message:
                                    "Special characters are not allowed.",
                                },
                              })}
                              value={formValues.LastName}
                              onChange={handleChange("LastName")}
                              pattern="^[-a-zA-Z0-9 ]*$"
                              data-webform-pattern-error="Special characters are not allowed."
                              data-drupal-selector="edit-lastname"
                              className="form-text required form-control"
                              data-msg-maxlength="Last name field has a maximum length of 25."
                              data-msg-required="Last name is required."
                              data-msg-pattern="Special characters are not allowed."
                              type="text"
                              id="edit-lastname"
                              size="60"
                              maxLength="25"
                              required="required"
                              aria-required="true"
                            />
                            <div className="error">
                              <ErrorMessage errors={errors} name="LastName">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>
                        {/* <div
                          className="row form-group js-form-wrapper form-wrapper"
                          id="edit-row-2-container"
                        >
                          <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-address1 js-form-item-address1 form-group">
                            <label
                              htmlFor="edit-address1"
                              {...(contactType !== "Rx Customer Service"
                                ? { className: "control-label form-required" }
                                : { className: "control-label" })}
                            >
                              Address
                            </label>

                            <input
                              {...register("Address1", {
                                ...(contactType !== "Rx Customer Service" && {
                                  required: "Street address is required.",
                                }),
                                maxLength: {
                                  value: 30,
                                  message:
                                    "Street address field has a maximum length of 30.",
                                },
                                pattern: {
                                  value: /^[-/a-zA-Z0-9 -]*$/,
                                  message:
                                    "Special characters are not allowed.",
                                },
                              })}
                              value={formValues.Address1}
                              onChange={handleChange("Address1")}
                              pattern="^[-/a-zA-Z0-9 -]*$"
                              className="form-text required form-control"
                              data-msg-maxlength="Street address field has a maximum length of 30."
                              data-msg-required="Street address is required."
                              data-msg-pattern="Special characters are not allowed."
                              type="text"
                              id="edit-permst1"
                              size="60"
                              maxLength="30"
                              required="required"
                              aria-required="true"
                            />
                            <div className="error">
                              <ErrorMessage errors={errors} name="Address1">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-address2 js-form-item-address2 form-group">
                            <label
                              htmlFor="edit-address2"
                              className="control-label"
                            >
                              Address 2
                            </label>

                            <input
                              {...register("Address2", {
                                maxLength: {
                                  value: 30,
                                  message:
                                    "Street address field has a maximum length of 30.",
                                },
                                pattern: {
                                  value: /^[-/a-zA-Z0-9 -]*$/,
                                  message:
                                    "Special characters are not allowed.",
                                },
                              })}
                              value={formValues.Address2}
                              onChange={handleChange("Address2")}
                              pattern="^[-/a-zA-Z0-9 ]*$"
                              className="form-text form-control"
                              data-msg-maxlength="Address 2 field has a maximum length of 30."
                              data-msg-required="Address 2 is required."
                              data-msg-pattern="Special characters are not allowed."
                              type="text"
                              id="edit-permst2"
                              size="60"
                              maxLength="30"
                            />
                            <div className="error">
                              <ErrorMessage errors={errors} name="Address2">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                        </div> */}
                        {/* <div
                          className="row form-group js-form-wrapper form-wrapper"
                          id="edit-row-3-container"
                        >
                          <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-city js-form-item-city form-group">
                            <label
                              htmlFor="edit-city"
                              {...(contactType !== "Rx Customer Service"
                                ? { className: "control-label form-required" }
                                : { className: "control-label" })}
                            >
                              City
                            </label>

                            <input
                              {...register("City", {
                                ...(contactType !== "Rx Customer Service" && {
                                  required: "City is required.",
                                }),
                                maxLength: {
                                  value: 30,
                                  message:
                                    "City field has a maximum length of 25.",
                                },
                                pattern: {
                                  value: /^[-/a-zA-Z0-9 -]*$/,
                                  message:
                                    "Special characters are not allowed.",
                                },
                              })}
                              value={formValues.City}
                              onChange={handleChange("City")}
                              pattern="^[-a-zA-Z0-9 ]*$"
                              className="form-text required form-control"
                              data-msg-maxlength="City field has a maximum length of 25."
                              data-msg-required="City is required."
                              data-msg-pattern="Special characters are not allowed."
                              type="text"
                              id="edit-permcity"
                              size="60"
                              maxLength="25"
                              required="required"
                              aria-required="true"
                            />
                            <div className="error">
                              <ErrorMessage errors={errors} name="City">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 form-item js-form-item form-type-select js-form-type-select form-item-stateofresidence js-form-item-stateofresidence form-group">
                            <label
                              htmlFor="edit-stateofresidence"
                              className="control-label form-required"
                            >
                              State
                            </label>

                            <div className="select-wrapper">
                              <select
                                {...register("StateOfResidence", {
                                  required: "State is required.",
                                })}
                                value={formValues.StateOfResidence}
                                onChange={handleChange("StateOfResidence")}
                                required="required"
                                aria-required="true"
                                className="form-select form-control"
                                id="edit-stateofresidence"
                                name="StateOfResidence"
                              >
                                <option value="">- Select -</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                              </select>
                            </div>
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="StateOfResidence"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                        </div> */}
                        <div
                          className="row form-group js-form-wrapper form-wrapper"
                          id="edit-row-4-container"
                        >
                          {/* <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-zip js-form-item-zip form-group">
                            <label
                              htmlFor="edit-zip"
                              {...(contactType !== "Rx Customer Service"
                                ? { className: "control-label form-required" }
                                : { className: "control-label" })}
                            >
                              Zip
                            </label>

                            <input
                              {...register("Zip", {
                                validate: {
                                  required: (value) => {
                                    if (
                                      !value &&
                                      contactType !== "Rx Customer Service"
                                    )
                                      return "Zip code (xxxxx) is required.";
                                    return true;
                                  },
                                },
                                minLength: {
                                  value: 5,
                                  message: "Please enter a 5 digit zip code.",
                                },
                                maxLength: {
                                  value: 5,
                                  message:
                                    "Zip code (xxxxx) field has a maximum length of 5.",
                                },
                                pattern: {
                                  value: /^[-/0-9 -]*$/,
                                  message:
                                    "Only numeric characters are allowed.",
                                },
                              })}
                              value={formValues.Zip}
                              onChange={handleChange("Zip")}
                              data-drupal-selector="edit-permzip5"
                              className="form-text required form-control"
                              data-msg-maxlength="Zip code (xxxxx) field has a maximum length of 5."
                              data-msg-required="Zip code (xxxxx) is required."
                              type="text"
                              id="edit-permzip5"
                              size="60"
                              maxLength="5"
                              required="required"
                              aria-required="true"
                            />
                            <div className="error">
                              <ErrorMessage errors={errors} name="Zip">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div> */}
                          <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-phonenumber js-form-item-phonenumber form-group">
                            <label
                              htmlFor="edit-phonenumber"
                              className="control-label form-required"
                            >
                              Phone number
                            </label>

                            <CSPhoneInput
                              name="PhoneNumber"
                              id="edit-PhoneNumber"
                              value={formValues.PhoneNumber}
                              onChange={handleChange("PhoneNumber")}
                              control={control}
                              rules={{
                                required: "Home phone number required.",
                              }}
                              // rules={{
                              //   required: (value) => {
                              //     if (
                              //       !!value
                              //       && contactType !== "Rx Customer Service"
                              //     )
                              //       return "Phone number is required.";
                              //     return true;
                              //   }
                              // }}
                            />
                            {/* <PhoneInput
                              name="PhoneNumber"
                              value={formValues.PhoneNumber}
                              onChange={handlePhoneNumber}
                              country="US"
                              id="PhoneNumber"
                              control={control}
                              rules={{
                                required: (value) => {
                                  if (
                                    value &&
                                    contactType !== "Rx Customer Service"
                                  )
                                    return "Phone number is required.";
                                  return true;
                                },
                                maxLength: {
                                  value: 14,
                                },
                              }}
                              defaultValue={formValues.PhoneNumber}
                              maxLength="14"
                              className="
                                    col-sm-12 col-md-12
                                    form-item
                                    js-form-item
                                    form-type-textfield
                                    js-form-type-textfield
                                    form-item-homephone
                                    js-form-item-homephone
                                    form-group"
                              style={{ border: "1px solid #ccc" }}
                            /> */}
                            <div className="error">
                              <ErrorMessage errors={errors} name="PhoneNumber">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row form-group js-form-wrapper form-wrapper"
                          id="edit-row-5-container"
                        >
                          <div className="col-md-6 col-sm-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-memberidnumber js-form-item-memberidnumber form-group">
                            <label
                              htmlFor="edit-memberidnumber"
                              className="control-label form-required"
                            >
                              Member ID
                            </label>

                            <input
                              {...register("MemberIDNumber", {
                                required:
                                  "MedicareBlue Rx member number (from your plan ID card) is required.",
                                maxLength: {
                                  value: 11,
                                  message:
                                    "MedicareBlue Rx member number (from your plan ID card) field has a maximum length of 9.",
                                },
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Valid Member ID is required",
                                },
                                validate: () => console.log("field validated"),
                              })}
                              value={formValues.MemberIDNumber}
                              onChange={handleChange("MemberIDNumber")}
                              className="form-text required form-control"
                              type="text"
                              id="edit-memberidnumber"
                              size="60"
                              maxLength="9"
                              required="required"
                              aria-required="true"
                            />
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="MemberIDNumber"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>

                          <div
                            className="col-sm-12 col-md-12 js-webform-states-hidden form-item js-form-item form-type-email js-form-type-email form-item-emailaddress js-form-item-emailaddress form-group"
                            // {...((contactType === "Rx EOC Request" )
                            // // || contactType === "Rx Pharmacy Directory Request") 
                            // && {
                            //   style: { display: "none" },
                            // })}
                          >
                            <label
                              htmlFor="edit-emailaddress"
                              className="control-label"
                            >
                              Email address
                            </label>

                            <input
                              {...register("EmailAddress", {
                                pattern: {
                                  value:
                                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message:
                                    "Please enter a valid email address.",
                                },
                                maxLength: {
                                  value: 255,
                                  message:
                                    "Email address field has a maximum length of 255.",
                                },
                              })}
                              value={formValues.EmailAddress}
                              onChange={handleChange("EmailAddress")}
                              data-drupal-selector="edit-email"
                              className="form-text form-control"
                              data-msg-maxlength="Email address field has a maximum length of 255."
                              data-msg-required="Email address is required."
                              type="text"
                              id="edit-email"
                              size="60"
                              maxLength="255"
                            />
                            <div className="error">
                              <ErrorMessage errors={errors} name="EmailAddress">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>

                          <div
                            className="col-md-6 col-sm-6 webform-has-field-prefix webform-has-field-suffix js-webform-states-hidden form-inline form-item js-form-item form-type-date js-form-type-date form-item-dateofbirth js-form-item-dateofbirth form-group"
                            {...(contactType !== "Rx Order ID Card" && {
                              style: { display: "none" },
                            })}
                          >
                            {/* <label
                              htmlFor="edit-dateofbirth"
                              className="control-label form-required"
                            >
                              Date of birth
                            </label> */}

                            <div className="input-group">
                              <span className="input-group-addon">
                                <div className="date_wrap_div">
                                  {/* <input
                                    {...register("DateOfBirth", {
                                      validate: {
                                        required: (value) => {
                                          if (
                                            !value &&
                                            contactType === "Rx Order ID Card"
                                          )
                                            return "Birthdate is required.";
                                          return true;
                                        },
                                      },
                                      min: {
                                        value:
                                          "1911-" + moment().format("MM-DD"),
                                        message:
                                          "The value in Birthdate has to be greater than " +
                                          moment().format("MM/DD") +
                                          "/1911.",
                                      },
                                      max: {
                                        value: moment().format("YYYY-MM-DD"),
                                        message:
                                          "The value in Birthdate has to be less than " +
                                          moment()
                                            .add(1, "days")
                                            .format("MM/DD/YYYY"),
                                      },
                                    })}
                                    onChange={handleChange("DateOfBirth")}
                                    type="date"
                                    value={formValues.DateOfBirth}
                                    //min="06/16/1911"
                                    data-min-year="1911"
                                    max="06/16/2021"
                                    data-max-year="2021"
                                    //type="text"
                                    data-drupal-date-format="m/d/Y"
                                    data-drupal-selector="edit-dob"
                                    className="
                                          form-text
                                          required
                                          form-control
                                          hasDatepicker
                                        "
                                    data-msg-max="The value in Birthdate has to be less than 06/16/2021."
                                    data-msg-min="The value in Birthdate has to be greater than 06/16/1911."
                                    data-msg-required="Birthdate is required."
                                    id="edit-dob"
                                    //value=""
                                    required="required"
                                    aria-required="true"
                                    placeholder="MM/DD/YYYY"
                                    autoComplete="chrome-off-48833200"
                                  /> */}

                                  {/* <DatePicker
                                    name="dob"
                                    onChange={handleBirthDate}
                                    onFocus={(e) => e.target.blur()}
                                    selected={birthDate}
                                    control={control}
                                    rules={{
                                      required: true,
                                    }}
                                    className="form-text
                                          required
                                          form-control hasDatepicker"
                                    placeholderText="DD/MM/YYYY"
                                    openToDate={d.setYear(d.getFullYear() - 65)}
                                    autoComplete="off"
                                  />
                                  <span className="input-group-addon"></span> */}
                                </div>
                              </span>
                            </div>
                            {/* <br />
                            <div
                              className="error"
                              {...(dobError === false && {
                                style: { display: "none" },
                              })}
                            >
                              Birthdate required.
                            </div>
                            <div className="error">
                              <ErrorMessage errors={errors} name="DateOfBirth">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div> */}
                          </div>

                          <div
                            className="col-md-12 js-webform-states-hidden form-item js-form-item form-type-select js-form-type-select form-item-plan-year js-form-item-plan-year form-group"
                            {...((contactType === "Rx Customer Service" ||
                              contactType === "Rx Order ID Card") && {
                              style: { display: "none" },
                            })}
                          >
                            <label
                              htmlFor="edit-plan-year"
                              className="control-label form-required"
                            >
                              Plan year
                            </label>

                            <div className="select-wrapper">
                              <select
                                {...register("DirectoryPlanYear", {
                                  validate: {
                                    required: (value) => {
                                      if (
                                        !value &&
                                        (contactType ===
                                          "Rx Pharmacy Directory Request" ||
                                          contactType === "Rx EOC Request" ||
                                          contactType === "Rx Formulary Drug List")
                                      )
                                        return "Plan year selection is required.";
                                      return true;
                                    },
                                  },
                                })}
                                value={formValues.DirectoryPlanYear}
                                onChange={handlePlanYearChange()}
                                className="form-select form-control"
                                id="edit-plan-year"
                                name="DirectoryPlanYear"
                              >
                                <option value="">- Select -</option>
                                {/* <option value="2022">2022</option> */}
                                {/* <option value="2023"
                                {...((contactType === "Rx Formulary Drug List") && {
                                  style: { display: "none" },
                                })}>                                
                                  2023
                                </option> */}
                                <option value="2025">2025</option>
                                <option value="2024">2024</option>
                              </select>
                            </div>
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="DirectoryPlanYear"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>

                          <div
                            className="col-sm-12 col-md-12 js-webform-states-hidden form-item js-form-item form-type-select js-form-type-select form-item-eocrequest js-form-item-eocrequest form-group"
                            {...(contactType !== "Rx EOC Request" && {
                              style: { display: "none" },
                            })}
                          >
                            <label
                              htmlFor="edit-eocrequest"
                              className="control-label form-required"
                            >
                              Evidence of coverage
                            </label>

                            <div className="select-wrapper">
                              <select
                                {...register("EvidenceOfCoverage", {
                                  validate: {
                                    required: (value) => {
                                      if (
                                        !value &&
                                        contactType === "Rx EOC Request"
                                      )
                                        return "Evidence of coverage is required.";
                                      return true;
                                    },
                                  },
                                })}
                                value={formValues.EvidenceOfCoverage}
                                onChange={handleChange("EvidenceOfCoverage")}
                                className="form-select form-control"
                                id="edit-eocrequest"
                                name="EvidenceOfCoverage"
                              >
                                <option value="">- Select -</option>
                                <option
                                  value="Select_EOC"
                                  {...(formValues.DirectoryPlanYear ==
                                    "2025" && {
                                    style: { display: "none" },
                                  })}
                                >
                                  Select plan
                                </option>
                                <option value="Standard_EOC">
                                  Standard plan
                                </option>
                                <option value="Premier_EOC">
                                  Premier plan
                                </option>
                                <option value="Group_EOC_4T">
                                  4-tier Employer group plans
                                </option>
                                <option value="Group_EOC_5T">
                                  5-tier Employer group plan
                                </option>
                              </select>
                            </div>
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="EvidenceOfCoverage"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>

                          <div
                            className="col-sm-12 col-md-12 js-webform-states-hidden form-item js-form-item form-type-select js-form-type-select form-item-eocrequest js-form-item-eocrequest form-group"
                            {...(contactType !== "Rx Formulary Drug List" && {
                              style: { display: "none" },
                            })}
                          >
                            <label
                              htmlFor="edit-eocrequest"
                              className="control-label form-required"
                            >
                              Formulary Drug List
                            </label>

                            <div className="select-wrapper">
                              <select
                                {...register("FormularyDrugList", {
                                  validate: {
                                    required: (value) => {
                                      if (
                                        !value &&
                                        contactType === "Rx Formulary Drug List"
                                      )
                                        return "Formulary Drug List is required.";
                                      return true;
                                    },
                                  },
                                })}
                                value={formValues.FormularyDrugList}
                                onChange={handleChange("FormularyDrugList")}
                                className="form-select form-control"
                                id="edit-eocrequest"
                                name="FormularyDrugList"
                              >
                                <option value="">- Select -</option>
                                <option value="Select_EOC"
                                {...((formValues.DirectoryPlanYear == "2025") && {
                                  style: { display: "none" },
                                })}>
                                  Select plan
                                </option>
                                <option value="Standard_EOC">
                                  Standard plan
                                </option>
                                <option value="Premier_EOC">                                  
                                  Premier plan
                                </option>
                                <option value="Group_EOC_4T">
                                  { contactType === "Rx Formulary Drug List" ? "4-tier Employer group benefits" : "4-tier Employer group plans"}                                  
                                </option>
                                <option value="Group_EOC_5T">
                                  { contactType === "Rx Formulary Drug List" ? "5-tier Employer group benefit" : "5-tier Employer group plan"}                                   
                                </option>
                              </select>
                            </div>
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="EvidenceOfCoverage"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>

                          <div
                            className="col-sm-12 col-md-12 js-webform-states-hidden form-item js-form-item form-type-select js-form-type-select form-item-stateofdirectory js-form-item-stateofdirectory form-group"
                            {...(contactType !==
                              "Rx Pharmacy Directory Request" && {
                              style: { display: "none" },
                            })}
                          >
                            <label
                              htmlFor="edit-stateofdirectory"
                              className="control-label form-required"
                            >
                              Pharmacy directory
                            </label>

                            <div className="select-wrapper">
                              <select
                                {...register("StateOfDirectory", {
                                  validate: {
                                    required: (value) => {
                                      if (
                                        !value &&
                                        contactType ===
                                          "Rx Pharmacy Directory Request"
                                      )
                                        return "State/plan selection is required.";
                                      return true;
                                    },
                                  },
                                })}
                                value={formValues.StateOfDirectory}
                                onChange={handleChange("StateOfDirectory")}
                                className="form-select form-control"
                                id="edit-stateofdirectory"
                                name="StateOfDirectory"
                              >
                                <option value="" selected="selected">
                                  - Select -
                                </option>
                                <option
                                  value="IA-SELECT"
                                  {...(formValues.DirectoryPlanYear ==
                                    "2025" && {
                                    style: { display: "none" },
                                  })}
                                >
                                  Iowa - Select plan
                                </option>
                                <option value="IA-STAN">
                                  Iowa - Standard plan
                                </option>
                                <option value="IA-PREM">
                                  Iowa - Premier plan
                                </option>
                                <option
                                  value="MN-SELECT"
                                  {...(formValues.DirectoryPlanYear ==
                                    "2025" && {
                                    style: { display: "none" },
                                  })}
                                >
                                  Minnesota - Select plan
                                </option>
                                <option value="MN-STAN">
                                  Minnesota – Standard plan
                                </option>
                                <option value="MN-PREM">
                                  Minnesota – Premier plan
                                </option>
                                <option
                                  value="MT-SELECT"
                                  {...(formValues.DirectoryPlanYear ==
                                    "2025" && {
                                    style: { display: "none" },
                                  })}
                                >
                                  Montana - Select plan
                                </option>
                                <option value="MT-STAN">
                                  Montana – Standard plan
                                </option>
                                <option value="MT-PREM">
                                  Montana – Premier plan
                                </option>
                                <option
                                  value="NE-SELECT"
                                  {...(formValues.DirectoryPlanYear ==
                                    "2025" && {
                                    style: { display: "none" },
                                  })}
                                >
                                  Nebraska - Select plan
                                </option>
                                <option value="NE-STAN">
                                  Nebraska – Standard plan
                                </option>
                                <option value="NE-PREM">
                                  Nebraska – Premier plan
                                </option>
                                <option
                                  value="ND-SELECT"
                                  {...(formValues.DirectoryPlanYear ==
                                    "2025" && {
                                    style: { display: "none" },
                                  })}
                                >
                                  North Dakota - Select plan
                                </option>
                                <option value="ND-STAN">
                                  North Dakota – Standard plan
                                </option>
                                <option value="ND-PREM">
                                  North Dakota – Premier plan
                                </option>
                                <option
                                  value="SD-SELECT"
                                  {...(formValues.DirectoryPlanYear ==
                                    "2025" && {
                                    style: { display: "none" },
                                  })}
                                >
                                  South Dakota - Select plan
                                </option>
                                <option value="SD-STAN">
                                  South Dakota – Standard plan
                                </option>
                                <option value="SD-PREM">
                                  South Dakota – Premier plan
                                </option>
                                <option
                                  value="WY-SELECT"
                                  {...(formValues.DirectoryPlanYear ==
                                    "2025" && {
                                    style: { display: "none" },
                                  })}
                                >
                                  Wyoming - Select plan
                                </option>
                                <option value="WY-STAN">
                                  Wyoming – Standard plan
                                </option>
                                <option value="WY-PREM">
                                  Wyoming – Premier plan
                                </option>
                                <option value="IA-GRP">
                                  Iowa - Employer Group plans
                                </option>
                                <option value="MN-GRP">
                                  Minnesota - Employer Group plans
                                </option>
                                <option value="NE-GRP">
                                  Nebraska - Employer Group plans
                                </option>
                                <option value="ND-GRP">
                                  North Dakota - Employer Group plans
                                </option>
                                <option value="SD-GRP">
                                  South Dakota - Employer Group plans
                                </option>
                              </select>
                            </div>
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="StateOfDirectory"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>

                          <div
                            className="col-sm-12 col-md-12 js-webform-states-hidden form-item js-form-item form-type-textarea js-form-type-textarea form-item-message js-form-item-message form-group"
                            {...(contactType !== "Rx Customer Service" && {
                              style: { display: "none" },
                            })}
                          >
                            <label
                              htmlFor="edit-message"
                              className="control-label"
                            >
                              Message
                            </label>

                            <div className="form-textarea-wrapper">
                              <textarea
                                {...register("message")}
                                value={formValues.message}
                                onChange={handleChange("message")}
                                className="form-textarea form-control resize-vertical"
                                id="edit-message"
                                name="message"
                                rows="5"
                                cols="60"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="js-webform-states-hidden form-actions webform-actions form-group js-form-wrapper form-wrapper"
                        id="edit-actions"
                      >
                        <Button
                          className="
                                  webform-button--next
                                  button
                                  js-form-submit
                                  form-submit
                                  btn-default btn"
                          onClick={handleSubmit}
                          disabled={isProcessing}
                          {...(isProcessing && {
                            style: { color: "black" },
                          })}
                        >
                          {isProcessing ? (
                            <div>
                              <span
                                className="spinner-border pmd-spinner spinner-border-sm text-light mr-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Processing...
                            </div>
                          ) : (
                            "Send"
                          )}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ContactUs;
