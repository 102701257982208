import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import { useStateMachine } from "little-state-machine";
import updateEnrollData from "../../../../state/actions/updateEnrollData";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";
import CSDateInput from "../../../Common/CSDateInput";

const Step2_2025 = ({
  handleChange,
  handleNext,
  handlePrevious,
  activeStep,
  steps,
}) => {
  const { state, actions } = useStateMachine({ updateEnrollData });

  // console.log(state.enrollData);

  const {
    register,
    watch,
    formState: { errors, isValid },
    getValues,
    trigger,
    control,
  } = useForm({ mode: "all", reValidateMode: "onBlur" });

  // const getPartADate = () => {
  //   return (
  //     state.enrollData.partAYear + "-" + state.enrollData.partAMonth + "-01"
  //   );
  // };

  //console.log('state.enrollData.partadate:'+state.enrollData.partadate);
  //console.log('state.enrollData.seplostcoveragedate:'+state.enrollData.seplostcoveragedate);

  const checkPartADate = () => {
    return !(state.enrollData.partadate);
    // return state.enrollData.partAYear === "" ||
    //   state.enrollData.partAMonth === ""
    //   ? true
    //   : false;
  };

  const handlePartADate = (e) => {
    actions.updateEnrollData({ partadate: e.target.value });
  };

  // const getPartBDate = () => {
  //   return (
  //     state.enrollData.partBYear + "-" + state.enrollData.partBMonth + "-01"
  //   );
  // };

  const checkPartBDate = () => {
    return !(state.enrollData.partbdate);
    // return state.enrollData.partBYear === "" ||
    //   state.enrollData.partBMonth === ""
    //   ? true
    //   : false;
  };

  const handlePartBDate = (e) => {
    actions.updateEnrollData({ partbdate: e.target.value });
  };

  // const getReqEffDate = () => {
  //   return (
  //     state.enrollData.reqEffYear + "-" + state.enrollData.reqEffMonth + "-01"
  //   );
  // };

  const checkReqEffDate = () => {
    return !(state.enrollData.effectivedate);
    // return state.enrollData.reqEffYear === "" ||
    //   state.enrollData.reqEffMonth === ""
    //   ? true
    //   : false;
  };

  const handleReqEffDate = (e) => {
    actions.updateEnrollData({ effectivedate: e.target.value });

    // switch (input) {
    //   case "reqeffdatemonth":
    //     actions.updateEnrollData({ reqEffMonth: e.target.value });
    //     break;
    //   case "reqeffdateyear":
    //     actions.updateEnrollData({ reqEffYear: e.target.value });
    //     break;
    //   default:
    //     break;
    // }
  };

  // const getSepOtherDate2 = () => {
  //   return (
  //     state.enrollData.sepOtherDate2Year +
  //     "-" +
  //     state.enrollData.sepOtherDate2Month +
  //     "-01"
  //   );
  // };

  const checkSepOtherDate2 = () => {
    return !(state.enrollData.sepotherdate2);
    // return state.enrollData.sepOtherDate2Year === "" ||
    //   state.enrollData.sepOtherDate2Month === ""
    //   ? true
    //   : false;
  };

  const handleSepOtherDate2 = (e) => {
    actions.updateEnrollData({ sepotherdate2: e.target.value });
    // switch (input) {
    //   case "sepotherdate2month":
    //     actions.updateEnrollData({ sepOtherDate2Month: e.target.value });
    //     break;
    //   case "sepotherdate2year":
    //     actions.updateEnrollData({ sepOtherDate2Year: e.target.value });
    //     break;
    //   default:
    //     break;
    // }
  };

  // const getSepOtherDate3 = () => {
  //   return (
  //     state.enrollData.sepOtherDate3Year +
  //     "-" +
  //     state.enrollData.sepOtherDate3Month +
  //     "-01"
  //   );
  // };

  const checkSepOtherDate3 = () => {
    return !(state.enrollData.sepotherdate3);
    // return state.enrollData.sepOtherDate3Year === "" ||
    //   state.enrollData.sepOtherDate3Month === ""
    //   ? true
    //   : false;
  };

  const handleSepOtherDate3 = (e) => {
    actions.updateEnrollData({ sepotherdate3: e.target.value });
    
    // switch (input) {
    //   case "sepotherdate3month":
    //     actions.updateEnrollData({ sepOtherDate3Month: e.target.value });
    //     break;
    //   case "sepotherdate3year":
    //     actions.updateEnrollData({ sepOtherDate3Year: e.target.value });
    //     break;
    //   default:
    //     break;
    // }
  };

  const handleAep = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({ aep: s, isAep: !state.enrollData.isAep });
  };

  const handleNewToMedicare = (e) => {
    let s = e.target.checked === true ? "Y" : " ";

    // if (s === " ") {
    //   actions.updateEnrollData({
    //     partadate: "",
    //     partbdate: "",
    //     // partADay: "",
    //     // partAMonth: "",
    //     // partAYear: "",
    //     // partBDay: "",
    //     // partBMonth: "",
    //     // partBYear: "",
    //   });
    // }

    actions.updateEnrollData({
      sepnewtomedicare: s,
      newToMedicare: !state.enrollData.newToMedicare,
    });
  };

  const handleSvcAreaMove = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepserviceareamove: s,
      svcAreaMove: !state.enrollData.svcAreaMove,
    });
  };

  const handleLtc = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({ sepltc: s, ltc: !state.enrollData.ltc });
  };

  const handleReturn2Us = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepreturn2us: s,
      return2us: !state.enrollData.return2us,
    });
  };

  const handleLostCoverage = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      seplostcoverage: s,
      lostCoverage: !state.enrollData.lostCoverage,
    });
  };

  const handleDisenrlSnp = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepdisenrlsnp: s,
      disenrlSnp: !state.enrollData.disenrlSnp,
    });
  };

  const handleNonRenewal = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepnonrenewal: s,
      nonRenewal: !state.enrollData.nonRenewal,
    });
  };

  const handleLostPdpPartB = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      seplostpdppartb: s,
      lostPdpPartB: !state.enrollData.lostPdpPartB,
    });
  };

  const handleMaOep = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({ sepmaoep: s, maOep: !state.enrollData.maOep });
  };

  const handleLeavingEmpCoverage = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepleavingempcoverage: s,
      leavingEmpCoverage: !state.enrollData.leavingEmpCoverage,
    });
  };

  const handleLeavingMa = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepleavingma: s,
      leavingMa: !state.enrollData.leavingMa,
    });
  };

  const handleExtraHelp = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepextrahelp: s,
      extraHelp: !state.enrollData.extraHelp,
    });
  };

  const handleMedicarePremCoverage = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepmedicarepremcoverage: s,
      medicarePremCoverage: !state.enrollData.medicarePremCoverage,
    });
  };

  const handleMedicaid = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepmedicaid: s,
      medicaid: !state.enrollData.medicaid,
    });
  };

  const handleLawfulPresence = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      seplawfulpresence: s,
      lawfulPresence: !state.enrollData.lawfulPresence,
    });
  };

  const handleChooseDifferent = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepchoosedifferent: s,
      chooseDifferent: !state.enrollData.chooseDifferent,
    });
  };

  const handleEmergency = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepemergency: s,
      emergency: state.enrollData.emergency,
    });
  };

  const handleIncarceration = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepincarceration: s,
      incarceration: !state.enrollData.incarceration,
    });
  };

  const handleOther = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({ sepother: s, other: !state.enrollData.other });
  };

  const handleDisenrollMcp = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({
      sepdisenrollmcp: s,
      disenrollMcp: !state.enrollData.disenrollMcp,
    });
  };

  const handleSeppap = (e) => {
    let s = e.target.checked === true ? "Y" : " ";
    actions.updateEnrollData({ seppap: s, pap: !state.enrollData.pap });
  };

  let p1 = activeStep === 0 ? true : false;

  const [partABDateError, setPartABDateError] = useState(false);
  const [reqEffDateError, setReqEffDateError] = useState(false);
  const [reqEffDateSeqError, setReqEffDateSeqError] = useState(false);
  const [sepOtherDate2Error, setSepOtherDate2Error] = useState(false);
  const [sepOtherDate2SeqError, setSepOtherDate2SeqError] = useState(false);
  const [sepOtherDate3Error, setSepOtherDate3Error] = useState(false);
  const [sepOtherDate3SeqError, setSepOtherDate3SeqError] = useState(false);

  const checkSelectorDates = () => {
    let count = 0;

    if (state.enrollData.newToMedicare) {
      let p = checkPartADate() && checkPartBDate();
      setPartABDateError(p);
      p && count++;
    }

    if (state.enrollData.svcAreaMove) {
      let p = checkReqEffDate();
      setReqEffDateError(p);
      p && count++;

      if (!p) {
        let d = moment(state.enrollData.effectivedate).isAfter(
          state.enrollData.sepserviceareamovedate
        );
        setReqEffDateSeqError(!d);
        !d && count++;
      }
    }

    if (state.enrollData.leavingEmpCoverage) {
      let p = checkSepOtherDate2();
      setSepOtherDate2Error(p);
      p && count++;

      if (!p) {
        let d = moment(state.enrollData.sepotherdate2).isAfter(
          state.enrollData.sepleavingempcoveragedate
        );
        setSepOtherDate2SeqError(!d);
        !d && count++;
      }
    }

    if (state.enrollData.lostCoverage) {
      let p = checkSepOtherDate3();
      setSepOtherDate3Error(p);
      p && count++;

      if (!p) {
        let d = moment(state.enrollData.sepotherdate3).isAfter(
          state.enrollData.seplostcoveragedate
        );
        setSepOtherDate3SeqError(!d);
        !d && count++;
      }
    }

    // console.log("Selector Date Errors: " + count);

    return count;
  };

  const getMultiSelectorDates = () => {
    // console.log("Ck PartA Date: " + checkPartADate());
    // if (state.enrollData.newToMedicare === true && checkPartADate() === false) {
    //   actions.updateEnrollData({ partadate: getPartADate() });
    if (state.enrollData.newToMedicare === false) {
      actions.updateEnrollData({ partadate: "" });
    }

    // // console.log("Ck PartB Date: " + checkPartBDate());
    // if (state.enrollData.newToMedicare === true && checkPartBDate() === false) {
    //   // console.log("ChkPartB: " + checkPartBDate());
    //   actions.updateEnrollData({ partbdate: getPartBDate() });
    if (state.enrollData.newToMedicare === false) {
      actions.updateEnrollData({ partbdate: "" });
    }


    // console.log("Ck SepOther2 Date: " + checkSepOtherDate2());
    // if (
    //   state.enrollData.leavingEmpCoverage === true &&
    //   checkSepOtherDate2() === false
    // ) {
    //   actions.updateEnrollData({ sepotherdate2: getSepOtherDate2() });
  
    if (state.enrollData.leavingEmpCoverage === false) {
       actions.updateEnrollData({ sepleavingempcoveragedate: "", sepotherdate2: "" });
    }
    
    // console.log("Ck SepOther3 Date: " + checkSepOtherDate3());
    // if (
    //   state.enrollData.lostCoverage === true &&
    //   checkSepOtherDate3() === false
    // ) {
    //   actions.updateEnrollData({ sepotherdate3: getSepOtherDate3() });
    if (state.enrollData.lostCoverage === false) {
      actions.updateEnrollData({ seplostcoveragedate: "", sepotherdate3: "" });
    }


    // console.log("Ck ReqEff Date: " + checkReqEffDate());
    // if (state.enrollData.svcAreaMove === true && checkReqEffDate() === false) {
    //   actions.updateEnrollData({ effectivedate: getReqEffDate() });
    if (state.enrollData.svcAreaMove === false) {
      actions.updateEnrollData({ sepserviceareamovedate: "", effectivedate: "" });
    }
  };

  const [displayCheckOptionError, setDisplayCheckOptionError] = useState(false);

  const handleNextClick = async () => {
    const isValidForm = await trigger();  

    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    var checkedOne = Array.prototype.slice
      .call(checkboxes)
      .some((x) => x.checked);

    getMultiSelectorDates();

    let dateVal = checkSelectorDates();

    //console.log('errors:' + errors);

    if (checkedOne && dateVal === 0 && isValidForm) {
      handleNext();
    } else if (!checkedOne) {
      setDisplayCheckOptionError(true);
    }
  };

  // console.log("sepOtherDate2: " + getSepOtherDate2());
  // console.log("sepotherdate2: " + state.enrollData.sepotherdate2);

  return (
    <>
      <div
        role="main"
        className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
      >
        <div className="row d-md-flex">
          <section className="col-sm-12 internal-page">
            <div className="region region-content">
              <div data-drupal-messages-fallback="" className="hidden"></div>
              <article className="webform full clearfix">
                <div className="content">
                  <div
                    className="
                      field
                      field--name-body
                      field--type-text-with-summary
                      field--label-hidden
                      field__item
                    "
                  >
                    <p>
                      <span className="form-required"> </span> indicates
                      required information
                    </p>
                  </div>

                  <div
                    className="
                      field
                      field--name-webform
                      field--type-webform
                      field--label-hidden
                      field__item
                    "
                  >
                    <form
                      className="
                        webform-submission-form
                        webform-submission-add-form
                        webform-submission-mbrx-enroll-2021-form
                        webform-submission-mbrx-enroll-2021-add-form
                        webform-submission-mbrx-enroll-2021-node-234-form
                        webform-submission-mbrx-enroll-2021-node-234-add-form
                        js-webform-disable-autosubmit js-webform-details-toggle
                        webform-details-toggle
                      "
                      data-drupal-selector="webform-submission-mbrx-enroll-2021-node-234-add-form"
                      data-msg-required="This field is required."
                      action="/mbrx-enroll-2021"
                      method="post"
                      id="webform-submission-mbrx-enroll-2021-node-234-add-form"
                      acceptCharset="UTF-8"
                      noValidate="noValidate"
                    >
                      <div
                        id="edit-step-2-title"
                        className="
                            form-item
                            js-form-item
                            form-type-processed-text
                            js-form-type-processed-text
                            form-item-
                            js-form-item-
                            form-no-label form-group
                          "
                      >
                        <h1>Enrollment details</h1>
                      </div>
                      <div
                        id="edit-step-2-instructions"
                        className="
                            form-item
                            js-form-item
                            form-type-processed-text
                            js-form-type-processed-text
                            form-item-
                            js-form-item-
                            form-no-label form-group
                          "
                      >
                        <p>
                          <strong>
                            Typically, you may enroll in a Medicare prescription
                            drug plan only during the annual enrollment period
                            from October 15 through December 7 of each year.
                          </strong>{" "}
                          Additionally, there are exceptions that may allow you
                          to enroll in a Medicare prescription drug plan outside
                          of the annual enrollment period. Please read the
                          following statements carefully and check the box if
                          the statement applies to you. By checking any of the
                          following boxes you are certifying that, to the best
                          of your knowledge, you are eligible for an enrollment
                          period. If we later determine that this information is
                          incorrect, you may be disenrolled.
                        </p>

                        <p>
                          <strong>
                            Note: A choice of effective dates is only allowed in
                            the enrollment situations identified below.
                          </strong>{" "}
                          In all other cases, or if you do not specify an
                          effective date, your effective date will be the first
                          of the month after your form is received by the plan.
                        </p>
                      </div>
                      <div>
                        <h2>Common enrollment choices</h2>
                      </div>
                      {/* AEP */}
                      {/* <div
                        className="
                                form-item
                                js-form-item
                                form-type-checkbox
                                js-form-type-checkbox
                                form-item-aep
                                js-form-item-aep
                                checkbox
                              "
                      >
                        <label
                          htmlFor="edit-aep"
                          className="control-label option"
                        >
                          <input
                            {...register("aep")}
                            value={state.enrollData.aep}
                            onChange={handleAep}
                            data-drupal-selector="edit-aep"
                            className="form-checkbox"
                            data-msg-required="I am enrolling during the annual enrollment period, October 15 through December 7, for a January 1, 2025 effective date. (Note: The enrollment application must be received by December 7 for the enrollment to be effective on January 1.) is required."
                            type="checkbox"
                            id="edit-aep"
                            {...(state.enrollData.isAep && { checked: true })}
                          />
                          I am enrolling during the annual enrollment period,
                          October 15 through December 7, for a January 1, 2025
                          effective date. (Note: the enrollment application must
                          be received by December 7 for the enrollment to be
                          effective on January 1.)
                        </label>
                      </div> */}

                      {/* Leaving Employer Coverage */}
                      <div
                        className="
                                form-item
                                js-form-item
                                form-type-checkbox
                                js-form-type-checkbox
                                form-item-sepleavingempcoverage
                                js-form-item-sepleavingempcoverage
                                checkbox
                              "
                      >
                        <label
                          htmlFor="edit-sepleavingempcoverage"
                          className="control-label option"
                        >
                          <input
                            {...register("sepleavingempcoverage")}
                            value={state.enrollData.sepleavingempcoverage}
                            onChange={handleLeavingEmpCoverage}
                            data-drupal-selector="edit-sepleavingempcoverage"
                            className="form-checkbox"
                            data-msg-required="I am leaving employer or union coverage is required."
                            type="checkbox"
                            id="edit-sepleavingempcoverage"
                            {...(state.enrollData.leavingEmpCoverage && {
                              checked: true,
                            })}
                          />
                          I am leaving employer or union coverage (choose this option if you are leaving employer coverage and newly enrolling in Medicare Part B).
                        </label>
                      </div>
                      <div
                        {...(!state.enrollData.leavingEmpCoverage && {
                          style: { display: "none" },
                        })}
                      >
                        <div
                          data-drupal-states='{"visible":{":input[name=\u0022sepleavingempcoverage\u0022]":{"checked":true}}}'
                          className="
                                webform-has-field-prefix
                                webform-has-field-suffix
                                js-webform-states-hidden
                                form-inline form-item
                                js-form-item
                                form-type-date
                                js-form-type-date
                                form-item-sepleavingempcoveragedate
                                js-form-item-sepleavingempcoveragedate
                                form-group
                              "
                        >
                                    <label>Leave date</label>
                                    &nbsp;&nbsp;&nbsp;
                              <div className="input-group">
                                <span className="input-group-addon">
                                <div className="date_wrap_div">
                                      <CSDateInput
                                            name="sepleavingempcoveragedate"
                                            // rules={{ 
                                            //   validate: {
                                            //     requiredValidation:  value => { if (!value) return "Part B date required."; return true }
                                            //   }
                                            // }}                                              
                                            control={control}
                                            //valueFormat={"YYYY-MM-DD"}
                                            //defaultValue={state.enrollData.sepleavingempcoveragedate}  // needed for initial form validation 
                                            value={state.enrollData.sepleavingempcoveragedate}
                                            onChange={handleChange("sepleavingempcoveragedate")}
                                            className="form-text
                                            required
                                            form-control"                                             
                                      />
                                    </div>
                                  </span>
                                </div>
                                <div className="error">
                                              <ErrorMessage errors={errors} name="sepleavingempcoveragedate">
                                                {({ messages }) =>
                                                  messages &&
                                                  Object.entries(messages).map(([type, message]) => (
                                                    <p key={type}>{message}</p>
                                                  ))
                                                }
                                              </ErrorMessage>
                                  </div>
                         
                        </div>

                        <div
                          className="
                                webform-has-field-prefix
                                webform-has-field-suffix
                                js-webform-states-hidden
                                form-item
                                js-form-item
                                form-type-datelist
                                js-form-type-datelist
                                form-item-sepotherdate2
                                js-form-item-sepotherdate2
                                form-group
                              "
                        >
                          <div
                            id="edit-sepotherdate2"
                            className="container-inline form-inline"
                          >
                            <div
                              className="
                                    form-item
                                    js-form-item
                                    form-type-select
                                    js-form-type-select
                                    form-item-sepotherdate2-month
                                    js-form-item-sepotherdate2-month
                                    form-no-label form-group
                                  "
                            >
                                <div className="select-wrapper">
                                  <label>Requested effective date</label>
                                  &nbsp;&nbsp;&nbsp;
                                  <div className="input-group">
                                    <span className="input-group-addon">
                                        <div className="date_wrap_div">
                                          <CSDateInput
                                                name="sepotherdate2"
                                                // rules={{ 
                                                //   validate: {
                                                //     requiredValidation:  value => { if (!value) return "Part B date required."; return true }
                                                //   }
                                                // }}  
                                                control={control}
                                                value={state.enrollData.sepotherdate2}
                                                onChange={handleSepOtherDate2}
                                                className="form-text
                                                required
                                                form-control"                                             
                                            />
                                          </div>
                                        </span>
                                      </div>
                                      <div className="error">
                                              <ErrorMessage errors={errors} name="sepotherdate2">
                                                {({ messages }) =>
                                                  messages &&
                                                  Object.entries(messages).map(([type, message]) => (
                                                    <p key={type}>{message}</p>
                                                  ))
                                                }
                                              </ErrorMessage>
                                  </div>
                              </div>
                            </div>
                           
                          </div>
                        </div>
                        <div>
                          {sepOtherDate2Error &&
                            state.enrollData.leavingEmpCoverage && (
                              <div className="error">
                                Please enter Requested effective date.
                              </div>
                            )}
                          {sepOtherDate2SeqError &&
                            state.enrollData.leavingEmpCoverage && (
                              <div className="error">
                                Effective date cannot be before leave date.
                              </div>
                            )}
                        </div>
                      </div>

                      {/* New To Medicare */}
                      <div
                        className="
                                form-item
                                js-form-item
                                form-type-checkbox
                                js-form-type-checkbox
                                form-item-sepnewtomedicare
                                js-form-item-sepnewtomedicare
                                checkbox
                              "
                      >
                        <label
                          htmlFor="edit-sepnewtomedicare"
                          className="control-label option"
                        >
                          <input
                            {...register("sepnewtomedicare")}
                            value={state.enrollData.sepnewtomedicare}
                            onChange={handleNewToMedicare}
                            data-drupal-selector="edit-sepnewtomedicare"
                            className="form-checkbox"
                            data-msg-required="I am new to Medicare. is required."
                            type="checkbox"
                            id="edit-sepnewtomedicare"
                            //name="sepnewtomedicare"
                            {...(state.enrollData.newToMedicare && {
                              checked: true,
                            })}
                          />
                          I am new to Medicare (Choose this option if you are turning or recently turned 65, or recently qualified for Medicare due to a disability).
                        </label>
                      </div>
                      <div
                        className="
                                js-webform-states-hidden
                                form-item
                                js-form-item
                                form-wrapper
                                js-form-wrapper
                                panel panel-default
                              "
                        data-drupal-selector="edit-part-a-b"
                        data-msg-required="My effective date for Medicare is: is required."
                        data-drupal-states='{"visible":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022sepnewtomedicare\u0022]":{"checked":true}}}'
                        id="edit-part-a-b"
                        {...(!state.enrollData.newToMedicare && {
                          style: { display: "none" },
                        })}
                      >
                        <div
                          className="
                                    webform-has-field-prefix
                                    webform-has-field-suffix
                                    form-item
                                    js-form-item
                                    form-type-datelist
                                    js-form-type-datelist
                                    form-item-effectivedate
                                    js-form-item-effectivedate
                                    form-group
                                  "
                        ></div>
                        <h4> My effective date for Medicare is:</h4>
                        {partABDateError && state.enrollData.newToMedicare && (
                          <div className="error">
                            Please enter at least one complete Part A or Part B
                            effective date.
                          </div>
                        )}

                        <div className="panel-body">
                          <div
                            className="
                                    webform-has-field-prefix
                                    webform-has-field-suffix
                                    form-item
                                    js-form-item
                                    form-type-datelist
                                    js-form-type-datelist
                                    form-item-effectivedate
                                    js-form-item-effectivedate
                                    form-group
                                  "
                          >
                            <div
                              id="edit-effectivedate"
                              className="container-inline form-inline"
                            >
                              
                                <div className="select-wrapper">
                                    <label>PART A</label>
                                    &nbsp;&nbsp;&nbsp;
                                  <div className="input-group">
                                    <span className="input-group-addon">
                                        <div className="date_wrap_div">
                                          <CSDateInput
                                            name="partadate"
                                            // rules={{ 
                                            //   validate: {
                                            //     requiredValidation:  value => { if (!value) return "Part A date required."; return true }
                                            //   }
                                            // }}  
                                            control={control}
                                            value={state.enrollData.partadate}
                                            onChange={handlePartADate}
                                            className="form-text
                                            required
                                            form-control"                                             
                                        />
                                      </div>
                                    </span>
                                  </div>
                                  </div>
                                  <div className="error">
                                              <ErrorMessage errors={errors} name="partadate">
                                                {({ messages }) =>
                                                  messages &&
                                                  Object.entries(messages).map(([type, message]) => (
                                                    <p key={type}>{message}</p>
                                                  ))
                                                }
                                              </ErrorMessage>
                                  </div>
                            </div>
                          </div>



                          <div
                            className="
                                    webform-has-field-prefix
                                    webform-has-field-suffix
                                    form-item
                                    js-form-item
                                    form-type-datelist
                                    js-form-type-datelist
                                    form-item-sepnewpartbdate
                                    js-form-item-sepnewpartbdate
                                    form-group
                                  "
                          >
                            <div
                              id="edit-sepnewpartbdate"
                              className="container-inline form-inline"
                            >

                                  <div className="select-wrapper">
                                    <label>PART B</label>
                                    &nbsp;&nbsp;&nbsp;
                                    <div className="input-group">
                                      <span className="input-group-addon">
                                        <div className="date_wrap_div">
                                          <CSDateInput
                                            name="partbdate"
                                            // rules={{ 
                                            //   validate: {
                                            //     requiredValidation:  value => { if (!value) return "Part B date required."; return true }
                                            //   }
                                            // }}  
                                            control={control}
                                            value={state.enrollData.partbdate}
                                            onChange={handlePartBDate}
                                            className="form-text
                                            required
                                            form-control"                                             
                                          />
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="error">
                                              <ErrorMessage errors={errors} name="partbdate">
                                                {({ messages }) =>
                                                  messages &&
                                                  Object.entries(messages).map(([type, message]) => (
                                                    <p key={type}>{message}</p>
                                                  ))
                                                }
                                              </ErrorMessage>
                                  </div>
                            </div>
                          </div>


                        </div>
                      </div>

                      {/* Lost Coverage */}
                      <div
                        className="
                                form-item
                                js-form-item
                                form-type-checkbox
                                js-form-type-checkbox
                                form-item-seplostcoverage
                                js-form-item-seplostcoverage
                                checkbox
                              "
                      >
                        <label
                          htmlFor="edit-seplostcoverage"
                          className="control-label option"
                        >
                          <input
                            {...register("seplostcoverage")}
                            value={state.enrollData.seplostcoverage}
                            onChange={handleLostCoverage}
                            data-drupal-selector="edit-seplostcoverage"
                            className="form-checkbox"
                            data-msg-required="I recently involuntarily lost my creditable prescription drug coverage (coverage as good as Medicare's) or was notified of the loss (whichever is later). is required."
                            type="checkbox"
                            id="edit-seplostcoverage"
                            {...(state.enrollData.lostCoverage && {
                              checked: true,
                            })}
                          />
                          I recently involuntarily lost my creditable
                          prescription drug coverage (coverage as good as
                          Medicare's) or was notified of the loss (whichever is
                          later).
                        </label>
                      </div>
                      <div
                        {...(!state.enrollData.lostCoverage && {
                          style: { display: "none" },
                        })}
                      >
                        <div
                          className="
                                webform-has-field-prefix
                                webform-has-field-suffix
                                js-webform-states-hidden
                                form-inline form-item
                                js-form-item
                                form-type-date
                                js-form-type-date
                                form-item-seplostcoveragedate
                                js-form-item-seplostcoveragedate
                                form-group
                              "
                        >
                          <h4>I lost my drug coverage on</h4>

                          <div className="input-group">
                            <span className="input-group-addon">
                              <div className="date_wrap_div">
                              <CSDateInput                               
                                  onChange={handleChange("seplostcoveragedate")}
                                  // rules={{ 
                                  //   validate: {
                                  //     requiredValidation:  value => { if (!value) return "I lost my drug coverage on is required."; return true }
                                  //   }
                                  // }}  
                                  value={state.enrollData.seplostcoveragedate}
                                  control={control}                                  
                                  //defaultValue={state.enrollData.seplostcoveragedate}  // needed for initial form validation 
                                  className="
                                        form-text form-control
                                        hasDatepicker
                                      "
                                  id="edit-seplostcoveragedate"
                                  name="seplostcoveragedate"

                                />
                                <span className="input-group-addon"></span>
                              </div>
                            </span>
                          </div>
                          <div className="error">
                                    <ErrorMessage errors={errors} name="seplostcoveragedate">
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(([type, message]) => (
                                          <p key={type}>{message}</p>
                                        ))
                                      }
                                    </ErrorMessage>
                                  </div>
                        </div>
                        <div
                          className="
                                webform-has-field-prefix
                                webform-has-field-suffix
                                js-webform-states-hidden
                                form-item
                                js-form-item
                                form-type-datelist
                                js-form-type-datelist
                                form-item-sepotherdate3
                                js-form-item-sepotherdate3
                                form-group
                              "
                        >
                          <h4>Requested effective date</h4>

                          <div>
                            {sepOtherDate3Error &&
                              state.enrollData.lostCoverage && (
                                <div className="error">
                                  Requested effective date is required.
                                </div>
                              )}
                          </div>
                          <div>
                            {sepOtherDate3SeqError &&
                              state.enrollData.lostCoverage && (
                                <div className="error">
                                  Effective date cannot be before termination
                                  date.
                                </div>
                              )}
                          </div>

                          <div
                            id="edit-sepotherdate3"
                            className="container-inline form-inline"
                          >
                                    <div className="input-group">
                                      <span className="input-group-addon">
                                        <div className="date_wrap_div">
                                          <CSDateInput
                                                name="sepotherdate3"
                                                control={control}
                                                value={state.enrollData.sepotherdate3}
                                                onChange={handleSepOtherDate3}
                                                className="form-text
                                                required
                                                form-control"                                             
                                            />
                                        </div>
                                      </span>

                                    </div>
                                    <div className="error">
                                        <ErrorMessage errors={errors} name="sepotherdate3">
                                          {({ messages }) =>
                                            messages &&
                                            Object.entries(messages).map(([type, message]) => (
                                              <p key={type}>{message}</p>
                                            ))
                                          }
                                        </ErrorMessage>
                                  </div>

                          </div>
                        </div>
                      </div>
                      {/* Service Area Move */}
                      <div
                        className="
                                form-item
                                js-form-item
                                form-type-checkbox
                                js-form-type-checkbox
                                form-item-sepserviceareamove
                                js-form-item-sepserviceareamove
                                checkbox
                              "
                      >
                        <label
                          htmlFor="edit-sepserviceareamove"
                          className="control-label option"
                        >
                          <input
                            {...register("sepserviceareamove")}
                            value={state.enrollData.sepserviceareamove}
                            onChange={handleSvcAreaMove}
                            data-drupal-selector="edit-sepserviceareamove"
                            className="form-checkbox"
                            data-msg-required="I recently moved outside of the service area for my current plan or I recently moved and this plan is a new option for me. is required."
                            type="checkbox"
                            id="edit-sepserviceareamove"
                            {...(state.enrollData.svcAreaMove && {
                              checked: true,
                            })}
                          />
                          I recently moved outside of the service area for my
                          current plan or I recently moved and this plan is a
                          new option for me.
                        </label>
                      </div>
                      <div
                        {...(!state.enrollData.svcAreaMove && {
                          style: { display: "none" },
                        })}
                      >
                        <div
                          className="
                                webform-has-field-prefix
                                webform-has-field-suffix
                                js-webform-states-hidden
                                form-inline form-item
                                js-form-item
                                form-type-date
                                js-form-type-date
                                form-item-sepserviceareamovedate
                                js-form-item-sepserviceareamovedate
                                form-group
                              "
                        >
                          <h4>I moved on</h4>

                          <div className="input-group">
                            <span className="input-group-addon">
                              <div className="date_wrap_div">
                                <CSDateInput
                                      name="sepserviceareamovedate"
                                      control={control}
                                      value={state.enrollData.sepserviceareamovedate}
                                      onChange={handleChange("sepserviceareamovedate")}
                                      className="form-text
                                      required
                                      form-control"                                             
                                  />
                              </div>
                            </span>
                          </div>   
                          <div className="error">
                                        <ErrorMessage errors={errors} name="sepserviceareamovedate">
                                          {({ messages }) =>
                                            messages &&
                                            Object.entries(messages).map(([type, message]) => (
                                              <p key={type}>{message}</p>
                                            ))
                                          }
                                        </ErrorMessage>
                                  </div>                     

                          <div>
                            <div
                              id="edit-sepserviceareamovedate--more"
                              className="
                                    js-webform-element-more
                                    webform-element-more
                                  "
                            >
                              <div
                                id="edit-sepserviceareamovedate--more--content"
                                className="webform-element-more--content"
                              >
                                Your requested effective date cannot be before
                                your move date.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="
                                webform-has-field-prefix
                                webform-has-field-suffix
                                js-webform-states-hidden
                                form-item
                                js-form-item
                                form-type-datelist
                                js-form-type-datelist
                                form-item-sepotherdate
                                js-form-item-sepotherdate
                                form-group
                              "
                          //style={{display: "None"}}
                        >
                          <h4>Requested effective date</h4>

                          {reqEffDateError && state.enrollData.svcAreaMove && (
                            <div className="error">
                              Please enter Requested effective date.
                            </div>
                          )}
                          {reqEffDateSeqError &&
                            state.enrollData.svcAreaMove && (
                              <div className="error">
                                Effective date cannot be before move date.
                              </div>
                            )}

                          <div
                            id="edit-effectivedate"
                            className="container-inline form-inline"
                          >
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <div className="date_wrap_div">
                                    <CSDateInput
                                          name="effectivedate"
                                          control={control}
                                          value={state.enrollData.effectivedate}
                                          onChange={handleReqEffDate}
                                          className="form-text
                                          required
                                          form-control"                                             
                                      />
                                  </div>
                                </span>
                              </div>
                              <div className="error">
                                        <ErrorMessage errors={errors} name="effectivedate">
                                          {({ messages }) =>
                                            messages &&
                                            Object.entries(messages).map(([type, message]) => (
                                              <p key={type}>{message}</p>
                                            ))
                                          }
                                        </ErrorMessage>
                                  </div>   
                          </div>
                        </div>
                      </div>
                      {/* LTC */}
                      <div
                        className="
                                form-item
                                js-form-item
                                form-type-checkbox
                                js-form-type-checkbox
                                form-item-sepltc
                                js-form-item-sepltc
                                checkbox
                              "
                      >
                        <label
                          htmlFor="edit-sepltc"
                          className="control-label option"
                        >
                          <input
                            {...register("sepltc")}
                            value={state.enrollData.sepltc}
                            onChange={handleLtc}
                            data-drupal-selector="edit-sepltc"
                            className="form-checkbox"
                            data-msg-required="I live in or recently moved out of a long-term care facility (for example, a nursing home or long-term care facility). is required."
                            type="checkbox"
                            id="edit-sepltc"
                            {...(state.enrollData.ltc && { checked: true })}
                          />
                          I live in or recently moved out of a long-term care
                          facility (for example, a nursing home or long-term
                          care facility).
                        </label>
                      </div>
                      <div
                        className="
                                webform-has-field-prefix
                                webform-has-field-suffix
                                js-webform-states-hidden
                                form-inline form-item
                                js-form-item
                                form-type-date
                                js-form-type-date
                                form-item-sepltcdate
                                js-form-item-sepltcdate
                                form-group
                              "
                        {...(!state.enrollData.ltc && {
                          style: { display: "none" },
                        })}
                      >
                        <h4>I moved/will move into/out of the facility on</h4>

                        <div className="input-group">
                          <span className="input-group-addon">
                            <div className="date_wrap_div">
                              <CSDateInput
                                      name="sepltcdate"
                                      control={control}
                                      rules={{ 
                                        validate: {
                                          requiredValidation:  value => { if (state.enrollData.ltc && !value) return "I moved/will move into/out of the facility on is required."; return true }
                                        }
                                      }}  
                                      value={state.enrollData.sepltcdate}
                                      onChange={handleChange("sepltcdate")}
                                      className="form-text
                                      required
                                      form-control"                                             
                                />
                              {/* <span className="input-group-addon"></span> */}
                            </div>
                          </span>
                        </div>
                        <div className="error">
                                        <ErrorMessage errors={errors} name="sepltcdate">
                                          {({ messages }) =>
                                            messages &&
                                            Object.entries(messages).map(([type, message]) => (
                                              <p key={type}>{message}</p>
                                            ))
                                          }
                                        </ErrorMessage>
                                  </div>   
                      </div>

                      {/******************************* 
                        Disenrollment/Coverage Changes
                      *********************************/}
                      <div>
                        <h2>I have had a change to my coverage</h2>
                      </div>
                      {/* Lost PDP Part B */}
                      <div
                        className="
                                form-item
                                js-form-item
                                form-type-checkbox
                                js-form-type-checkbox
                                form-item-seplostpdppartb
                                js-form-item-seplostpdppartb
                                checkbox
                              "
                      >
                        <label
                          htmlFor="edit-seplostpdppartb"
                          className="control-label option"
                        >
                          <input
                            {...register("seplostpdppartb")}
                            value={state.enrollData.seplostpdppartb}
                            onChange={handleLostPdpPartB}
                            data-drupal-selector="edit-seplostpdppartb"
                            className="form-checkbox"
                            data-msg-required="I have been involuntarily disenrolled from a Medicare Advantage-prescription drug plan due to loss of Part B but continue to be entitled to Part A is required."
                            type="checkbox"
                            id="edit-seplostpdppartb"
                            {...(state.enrollData.lostPdpPartB && {
                              checked: true,
                            })}
                          />
                          I have been involuntarily disenrolled from a Medicare
                          Advantage-prescription drug plan due to loss of Part B
                          but continue to be entitled to Part A.
                        </label>
                      </div>
                      <div
                        data-drupal-states='{"visible":{":input[name=\u0022seplostpdppartb\u0022]":{"checked":true}}}'
                        className="
                                webform-has-field-prefix
                                webform-has-field-suffix
                                js-webform-states-hidden
                                form-inline form-item
                                js-form-item
                                form-type-date
                                js-form-type-date
                                form-item-seplostpdppartbdate
                                js-form-item-seplostpdppartbdate
                                form-group
                              "
                        {...(!state.enrollData.lostPdpPartB && {
                          style: { display: "none" },
                        })}
                      >
                        <label
                          htmlFor="edit-seplostpdppartbdate"
                          className="control-label"
                        >
                          Disenrollment date
                        </label>

                        <div className="input-group">
                          <span className="input-group-addon">
                            <div className="date_wrap_div">
                              <CSDateInput
                                      name="seplostpdppartbdate"
                                      control={control}
                                      rules={{ 
                                        validate: {
                                          requiredValidation:  value => { if (state.enrollData.lostPdpPartB && !value) return "Disenrollment date is required."; return true }
                                        }
                                      }}  
                                      value={state.enrollData.seplostpdppartbdate}
                                      onChange={handleChange("seplostpdppartbdate")}
                                      className="form-text
                                      required
                                      form-control"                                             
                                />
                             
                            </div>
                          </span>
                        </div>
                        <div className="error">
                              <ErrorMessage errors={errors} name="seplostpdppartbdate">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                  ))
                                }
                              </ErrorMessage>
                        </div>  
                      </div>

                      {/* Disenroll Special Needs Plan */}
                      <div
                        className="
                                form-item
                                js-form-item
                                form-type-checkbox
                                js-form-type-checkbox
                                form-item-sepdisenrlsnp
                                js-form-item-sepdisenrlsnp
                                checkbox
                              "
                      >
                        <label
                          htmlFor="edit-sepdisenrlsnp"
                          className="control-label option"
                        >
                          <input
                            {...register("sepdisenrlsnp")}
                            value={state.enrollData.sepdisenrlsnp}
                            onChange={handleDisenrlSnp}
                            data-drupal-selector="edit-sepdisenrlsnp"
                            className="form-checkbox"
                            data-msg-required="I am being disenrolled from a special needs plan because my condition does not qualify me for that plan is required."
                            type="checkbox"
                            id="edit-sepdisenrlsnp"
                            {...(state.enrollData.disenrlSnp && {
                              checked: true,
                            })}
                          />
                          I am being disenrolled from a special needs plan
                          because my condition does not qualify me for that plan.
                        </label>
                      </div>
                      <div
                        className="
                                webform-has-field-prefix
                                webform-has-field-suffix
                                js-webform-states-hidden
                                form-inline form-item
                                js-form-item
                                form-type-date
                                js-form-type-date
                                form-item-sepdisenrlsnpdate
                                js-form-item-sepdisenrlsnpdate
                                form-group
                              "
                        {...(!state.enrollData.disenrlSnp && {
                          style: { display: "none" },
                        })}
                      >
                        <h4>Disenrollment date</h4>

                        <div className="input-group">
                          <span className="input-group-addon">
                            <div className="date_wrap_div">
                                <CSDateInput
                                      name="sepdisenrlsnpdate"
                                      control={control}
                                      rules={{ 
                                        validate: {
                                          requiredValidation:  value => { if (state.enrollData.disenrlSnp && !value) return "Disenrollment date is required."; return true }
                                        }
                                      }}  
                                      value={state.enrollData.sepdisenrlsnpdate}
                                      onChange={handleChange("sepdisenrlsnpdate")}
                                      className="form-text
                                      required
                                      form-control"                                             
                                  />
                            </div>
                          </span>
                        </div>
                        <div className="error">
                              <ErrorMessage errors={errors} name="sepdisenrlsnpdate">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                  ))
                                }
                              </ErrorMessage>
                        </div> 
                      </div>
                      {/* MA OEP */}
                      <div
                        className="
                                form-item
                                js-form-item
                                form-type-checkbox
                                js-form-type-checkbox
                                form-item-sepmaoep
                                js-form-item-sepmaoep
                                checkbox
                              "
                      >
                        <label
                          htmlFor="edit-sepmaoep"
                          className="control-label option"
                        >
                          <input
                            {...register("sepmaoep")}
                            value={state.enrollData.sepmaoep}
                            onChange={handleMaOep}
                            data-drupal-selector="edit-sepmaoep"
                            className="form-checkbox"
                            data-msg-required="I am enrolled in a Medicare Advantage plan and want to make a change during the Medicare Advantage open enrollment period (MA OEP). is required."
                            type="checkbox"
                            id="edit-sepmaoep"
                            {...(state.enrollData.maOep && { checked: true })}
                          />
                          I am enrolled in a Medicare Advantage plan and want to
                          make a change during the Medicare Advantage open
                          enrollment period (MA OEP).
                        </label>
                      </div>

                      {/* Leaving MA */}
                      <div
                        className="
                                form-item
                                js-form-item
                                form-type-checkbox
                                js-form-type-checkbox
                                form-item-sepleavingma
                                js-form-item-sepleavingma
                                checkbox
                              "
                      >
                        <label
                          htmlFor="edit-sepleavingma"
                          className="control-label option"
                        >
                          <input
                            {...register("sepleavingma")}
                            value={state.enrollData.sepleavingma}
                            onChange={handleLeavingMa}
                            data-drupal-selector="edit-sepleavingma"
                            className="form-checkbox"
                            data-msg-required="I am leaving my Medicare Advantage plan within 12 months of my initial enrollment under a special enrollment period to go back to a Medicare supplement insurance (Medigap) plan is required."
                            type="checkbox"
                            id="edit-sepleavingma"
                            {...(state.enrollData.leavingMa && {
                              checked: true,
                            })}
                          />
                          I am leaving my Medicare Advantage plan within 12
                          months of my initial enrollment under a special
                          enrollment period to go back to a Medicare supplement
                          insurance (Medigap) plan.
                        </label>
                      </div>
                      <div
                        {...(!state.enrollData.leavingMa && {
                          style: { display: "none" },
                        })}
                      >
                        <div
                          data-drupal-states='{"visible":{":input[name=\u0022sepleavingma\u0022]":{"checked":true}}}'
                          className="
                                webform-has-field-prefix
                                webform-has-field-suffix
                                js-webform-states-hidden
                                form-inline form-item
                                js-form-item
                                form-type-date
                                js-form-type-date
                                form-item-sepleavingmadate
                                js-form-item-sepleavingmadate
                                form-group
                              "
                        >
                          <label
                            htmlFor="edit-sepleavingmadate"
                            className="control-label"
                          >
                            Leave date
                          </label>

                          <div className="input-group">
                            <span className="input-group-addon">
                              <div className="date_wrap_div">
                                  <CSDateInput
                                      name="sepleavingmadate"
                                      control={control}
                                      rules={{ 
                                        validate: {
                                          requiredValidation:  value => { if (state.enrollData.leavingMa && !value) return "Leave date is required."; return true }
                                        }
                                      }}  
                                      value={state.enrollData.sepleavingmadate}
                                      onChange={handleChange("sepleavingmadate")}
                                      className="form-text
                                      required
                                      form-control"                                             
                                  />
                               
                              </div>
                            </span>
                          </div>
                          <div className="error">
                              <ErrorMessage errors={errors} name="sepleavingmadate">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                  ))
                                }
                              </ErrorMessage>
                        </div> 
                        </div>
                      </div>

                      {/* Disenroll Cost Plan */}
                      <div
                        className="
                                form-item
                                js-form-item
                                form-type-checkbox
                                js-form-type-checkbox
                                form-item-sepdisenrollmcp
                                js-form-item-sepdisenrollmcp
                                checkbox
                              "
                      >
                        <label
                          htmlFor="edit-sepdisenrollmcp"
                          className="control-label option"
                        >
                          <input
                            {...register("sepdisenrollmcp")}
                            onChange={handleDisenrollMcp}
                            data-drupal-selector="edit-sepdisenrollmcp"
                            className="form-checkbox"
                            data-msg-required="I have disenrolled from a Medicare cost plan and the plan's optional supplemental Part D benefits is required."
                            type="checkbox"
                            id="edit-sepdisenrollmcp"
                            name="sepdisenrollmcp"
                            value="1"
                            {...(state.enrollData.disenrollMcp && {
                              checked: true,
                            })}
                          />
                          I have disenrolled from a Medicare cost plan and the
                          plan's optional supplemental Part D benefits.
                        </label>
                      </div>
                      <div
                        data-drupal-states='{"visible":{":input[name=\u0022sepdisenrollmcp\u0022]":{"checked":true}}}'
                        className="
                                webform-has-field-prefix
                                js-webform-states-hidden
                                form-inline form-item
                                js-form-item
                                form-type-date
                                js-form-type-date
                                form-item-sepdisenrollmcpdate
                                js-form-item-sepdisenrollmcpdate
                                form-group
                              "
                        {...(!state.enrollData.disenrollMcp && {
                          style: { display: "none" },
                        })}
                      >
                        <label
                          htmlFor="edit-sepdisenrollmcpdate"
                          className="control-label"
                        >
                          Disenrollment date
                        </label>

                        <div className="input-group">
                          <span className="input-group-addon">
                            <div className="date_wrap_div">
                              <CSDateInput
                                      name="sepdisenrollmcpdate"
                                      control={control}
                                      rules={{ 
                                        validate: {
                                          requiredValidation:  value => { if (state.enrollData.disenrollMcp && !value) return "Disenrollment  date is required."; return true }
                                        }
                                      }}
                                      value={state.enrollData.sepdisenrollmcpdate}
                                      onChange={handleChange("sepdisenrollmcpdate")}
                                      className="form-text
                                      required
                                      form-control"                                             
                                  />
                            </div>
                          </span>
                        </div>
                        <div className="error">
                              <ErrorMessage errors={errors} name="sepdisenrollmcpdate">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                  ))
                                }
                              </ErrorMessage>
                        </div> 
                      </div>

                      {/* Change */}
                      <div
                        className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-sepextrahelp
                                  js-form-item-sepextrahelp
                                  checkbox
                                "
                      >
                        <label
                          htmlFor="edit-sepextrahelp"
                          className="control-label option"
                        >
                          <input
                            {...register("sepextrahelp")}
                            value={state.enrollData.sepextrahelp}
                            onChange={handleExtraHelp}
                            data-drupal-selector="edit-sepextrahelp"
                            className="form-checkbox"
                            data-msg-required="I recently had a change in my Extra Help paying for Medicare prescription drug coverage (newly got Extra Help, had a change in the level of Extra Help, or lost Extra Help) is required."
                            type="checkbox"
                            id="edit-sepextrahelp"
                            {...(state.enrollData.extraHelp && {
                              checked: true,
                            })}
                          />
                          I recently had a change in my Extra Help paying for
                          Medicare prescription drug coverage (newly got Extra
                          Help, had a change in the level of Extra Help, or lost
                          Extra Help).
                        </label>
                      </div>
                      <div
                        data-drupal-states='{"visible":{":input[name=\u0022sepextrahelp\u0022]":{"checked":true}}}'
                        className="
                                  webform-has-field-prefix
                                  webform-has-field-suffix
                                  js-webform-states-hidden
                                  form-inline form-item
                                  js-form-item
                                  form-type-date
                                  js-form-type-date
                                  form-item-sepextrahelpdate
                                  js-form-item-sepextrahelpdate
                                  form-group
                                "
                        {...(!state.enrollData.extraHelp && {
                          style: { display: "none" },
                        })}
                      >
                        <label
                          htmlFor="edit-sepextrahelpdate"
                          className="control-label"
                        >
                          Date of change
                        </label>

                        <div className="input-group">
                          <span className="input-group-addon">
                            <div className="date_wrap_div">
                                <CSDateInput
                                      name="sepextrahelpdate"
                                      control={control}
                                      rules={{ 
                                        validate: {
                                          requiredValidation:  value => { if (state.enrollData.extraHelp && !value) return "Date of change is required."; return true }
                                        }
                                      }}
                                      value={state.enrollData.sepextrahelpdate}
                                      onChange={handleChange("sepextrahelpdate")}
                                      className="form-text
                                      required
                                      form-control"                                             
                                  />
                              {/* <span className="input-group-addon"></span> */}
                            </div>
                          </span>
                        </div>
                        <div className="error">
                              <ErrorMessage errors={errors} name="sepextrahelpdate">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                  ))
                                }
                              </ErrorMessage>
                        </div> 
                      </div>
                      {/* Medicaid Change */}
                      <div
                        className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-sepmedicaid
                                  js-form-item-sepmedicaid
                                  checkbox
                                "
                      >
                        <label
                          htmlFor="edit-sepmedicaid"
                          className="control-label option"
                        >
                          <input
                            {...register("sepmedicaid")}
                            value={state.enrollData.sepmedicaid}
                            onChange={handleMedicaid}
                            data-drupal-selector="edit-sepmedicaid"
                            className="form-checkbox"
                            data-msg-required="I recently had a change in my Medicaid (newly got Medicaid, had a change in level of Medicaid assistance, or lost Medicaid.) is required."
                            type="checkbox"
                            id="edit-sepmedicaid"
                            {...(state.enrollData.medicaid && {
                              checked: true,
                            })}
                          />
                          I recently had a change in my Medicaid (newly got
                          Medicaid, had a change in level of Medicaid
                          assistance, or lost Medicaid).
                        </label>
                      </div>
                      <div
                        data-drupal-states='{"visible":{":input[name=\u0022sepmedicaid\u0022]":{"checked":true}}}'
                        className="
                                  webform-has-field-prefix
                                  webform-has-field-suffix
                                  js-webform-states-hidden
                                  form-inline form-item
                                  js-form-item
                                  form-type-date
                                  js-form-type-date
                                  form-item-sepmedicaiddate
                                  js-form-item-sepmedicaiddate
                                  form-group
                                "
                        {...(!state.enrollData.medicaid && {
                          style: { display: "none" },
                        })}
                      >
                        <label
                          htmlFor="edit-sepmedicaiddate"
                          className="control-label"
                        >
                          Date of change
                        </label>

                        <div className="input-group">
                          <span className="input-group-addon">
                            <div className="date_wrap_div">
                              <CSDateInput
                                      name="sepmedicaiddate"
                                      control={control}
                                      rules={{ 
                                        validate: {
                                          requiredValidation:  value => { if (state.enrollData.medicaid && !value) return "Date of change is required."; return true }
                                        }
                                      }}
                                      value={state.enrollData.sepmedicaiddate}
                                      onChange={handleChange("sepmedicaiddate")}
                                      className="form-text
                                      required
                                      form-control"                                             
                                  />
                            </div>
                          </span>
                        </div>
                        <div className="error">
                              <ErrorMessage errors={errors} name="sepmedicaiddate">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                  ))
                                }
                              </ErrorMessage>
                        </div> 
                      </div>

                      {/******************************* 
                        Other
                      *********************************/}
                      <div>
                        <h2>Other reasons</h2>
                      </div>

                      {/* Medicare/Medicaid No Change */}
                      <div
                        className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-sepmedicarepremcoverage
                                  js-form-item-sepmedicarepremcoverage
                                  checkbox
                                "
                      >
                        <label
                          htmlFor="edit-sepmedicarepremcoverage"
                          className="control-label option"
                        >
                          <input
                            {...register("sepmedicarepremcoverage")}
                            value={state.enrollData.sepmedicarepremcoverage}
                            onChange={handleMedicarePremCoverage}
                            data-drupal-selector="edit-sepmedicarepremcoverage"
                            className="form-checkbox"
                            data-msg-required="I have both Medicare and Medicaid (or my state helps pay for my Medicare premiums) or I get Extra Help paying for my Medicare prescription drug coverage, but I haven’t had a change) is required."
                            type="checkbox"
                            id="edit-sepmedicarepremcoverage"
                            {...(state.enrollData.medicarePremCoverage && {
                              checked: true,
                            })}
                          />
                          I have both Medicare and Medicaid (or my state helps
                          pay for my Medicare premiums) or I get Extra Help
                          paying for my Medicare prescription drug coverage, but
                          I haven’t had a change).
                        </label>
                      </div>
                      {/* Return To US */}
                      <div
                        className="
                                form-item
                                js-form-item
                                form-type-checkbox
                                js-form-type-checkbox
                                form-item-sepreturn2us
                                js-form-item-sepreturn2us
                                checkbox
                              "
                      >
                        <label
                          htmlFor="edit-sepreturn2us"
                          className="control-label option"
                        >
                          <input
                            {...register("sepreturn2us")}
                            value={state.enrollData.sepreturn2us}
                            onChange={handleReturn2Us}
                            data-drupal-selector="edit-sepreturn2us"
                            className="form-checkbox"
                            data-msg-required="I recently returned to the United States after living permanently outside of the U.S. is required."
                            type="checkbox"
                            id="edit-sepreturn2us"
                            {...(state.enrollData.return2us && {
                              checked: true,
                            })}
                          />
                          I recently returned to the United States after living
                          permanently outside of the U.S.
                        </label>
                      </div>
                      <div
                        className="
                                webform-has-field-prefix
                                webform-has-field-suffix
                                js-webform-states-hidden
                                form-inline form-item
                                js-form-item
                                form-type-date
                                js-form-type-date
                                form-item-sepreturn2usdate
                                js-form-item-sepreturn2usdate
                                form-group
                              "
                        {...(!state.enrollData.return2us && {
                          style: { display: "none" },
                        })}
                      >
                        <h4>I returned to the U.S. on</h4>

                        <div className="input-group">
                          <span className="input-group-addon">
                            <div className="date_wrap_div">
                              <CSDateInput
                                      name="sepreturn2usdate"
                                      control={control}
                                      rules={{ 
                                        validate: {
                                          requiredValidation:  value => { if (state.enrollData.return2us && !value) return "I returned to the U.S. on is required."; return true }
                                        }
                                      }}
                                      value={state.enrollData.sepreturn2usdate}
                                      onChange={handleChange("sepreturn2usdate")}
                                      className="form-text
                                      required
                                      form-control"                                             
                                  />
                              {/* <span className="input-group-addon"></span> */}
                            </div>
                          </span>
                        </div>
                        <div className="error">
                              <ErrorMessage errors={errors} name="sepreturn2usdate">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                  ))
                                }
                              </ErrorMessage>
                        </div> 
                      </div>
                      {/* MT Big Sky */}
                      <div
                        className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-sepmedicarepremcoverage
                                  js-form-item-sepmedicarepremcoverage
                                  checkbox
                                "
                        {...(state.enrollData.permstate !== "MT" && {
                          style: { display: "none" },
                        })}
                      >
                        <label
                          htmlFor="edit-sepmedicarepremcoverage"
                          className="control-label option"
                        >
                          <input
                            {...register("seppap")}
                            value={state.enrollData.seppap}
                            onChange={handleSeppap}
                            data-drupal-selector="edit-seppap"
                            className="form-checkbox"
                            type="checkbox"
                            id="edit-seppap"
                            {...(state.enrollData.pap && { checked: true })}
                          />
                          I belong to Big Sky Rx (a state pharmaceutical
                          assistance program) provided by the state of Montana.
                        </label>
                      </div>

                      {/* Lawful Presence */}
                      <div
                        className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-seplawfulpresence
                                  js-form-item-seplawfulpresence
                                  checkbox
                                "
                      >
                        <label
                          htmlFor="edit-seplawfulpresence"
                          className="control-label option"
                        >
                          <input
                            {...register("seplawfulpresence")}
                            value={state.enrollData.seplawfulpresence}
                            onChange={handleLawfulPresence}
                            data-drupal-selector="edit-seplawfulpresence"
                            className="form-checkbox"
                            data-msg-required="I recently obtained lawful presence in the United States is required."
                            type="checkbox"
                            id="edit-seplawfulpresence"
                            {...(state.enrollData.lawfulPresence && {
                              checked: true,
                            })}
                          />
                          I recently obtained lawful presence in the United States.
                        </label>
                      </div>
                      <div
                        data-drupal-states='{"visible":{":input[name=\u0022seplawfulpresence\u0022]":{"checked":true}}}'
                        className="
                                  webform-has-field-prefix
                                  webform-has-field-suffix
                                  js-webform-states-hidden
                                  form-inline form-item
                                  js-form-item
                                  form-type-date
                                  js-form-type-date
                                  form-item-seplawfulpresencedate
                                  js-form-item-seplawfulpresencedate
                                  form-group
                                "
                        {...(!state.enrollData.lawfulPresence && {
                          style: { display: "none" },
                        })}
                      >
                        <label
                          htmlFor="edit-seplawfulpresencedate"
                          className="control-label"
                        >
                          I got this status on
                        </label>

                        <div className="input-group">
                          <span className="input-group-addon">
                            <div className="date_wrap_div">
                              <CSDateInput
                                      name="seplawfulpresencedate"
                                      control={control}
                                      rules={{ 
                                        validate: {
                                          requiredValidation:  value => { if (state.enrollData.lawfulPresence && !value) return "I got this status on is required."; return true }
                                        }
                                      }}
                                      value={state.enrollData.seplawfulpresencedate}
                                      onChange={handleChange("seplawfulpresencedate")}
                                      className="form-text
                                      required
                                      form-control"                                             
                                  />                             
                              {/* <span className="input-group-addon"></span> */}
                            </div>
                          </span>
                        </div>
                        <div className="error">
                              <ErrorMessage errors={errors} name="seplawfulpresencedate">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                  ))
                                }
                              </ErrorMessage>
                        </div> 
                      </div>
                      {/* Choose Different */}
                      <div
                        className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-sepchoosedifferent
                                  js-form-item-sepchoosedifferent
                                  checkbox
                                "
                      >
                        <label
                          htmlFor="edit-sepchoosedifferent"
                          className="control-label option"
                        >
                          <input
                            {...register("sepchoosedifferent")}
                            value={state.enrollData.sepchoosedifferent}
                            onChange={handleChooseDifferent}
                            data-drupal-selector="edit-sepchoosedifferent"
                            className="form-checkbox"
                            data-msg-required="I was enrolled in a plan by Medicare (or my state) and I want to choose a different plan is required."
                            type="checkbox"
                            id="edit-sepchoosedifferent"
                            {...(state.enrollData.chooseDifferent && {
                              checked: true,
                            })}
                          />
                          I was enrolled in a plan by Medicare (or my state) and
                          I want to choose a different plan.
                        </label>
                      </div>
                      <div
                        data-drupal-states='{"visible":{":input[name=\u0022sepchoosedifferent\u0022]":{"checked":true}}}'
                        className="
                                  webform-has-field-prefix
                                  webform-has-field-suffix
                                  js-webform-states-hidden
                                  form-inline form-item
                                  js-form-item
                                  form-type-date
                                  js-form-type-date
                                  form-item-sepchoosedifferentdate
                                  js-form-item-sepchoosedifferentdate
                                  form-group
                                "
                        {...(!state.enrollData.chooseDifferent && {
                          style: { display: "none" },
                        })}
                      >
                        <label
                          htmlFor="edit-sepchoosedifferentdate"
                          className="control-label"
                        >
                          My enrollment in that plan started on
                        </label>

                        <div className="input-group">
                          <span className="input-group-addon">
                            <div className="date_wrap_div">
                              <CSDateInput
                                      name="sepchoosedifferentdate"
                                      control={control}
                                      rules={{ 
                                        validate: {
                                          requiredValidation:  value => { if (state.enrollData.chooseDifferent && !value) return "My enrollment in that plan started on is required."; return true }
                                        }
                                      }}
                                      value={state.enrollData.sepchoosedifferentdate}
                                      onChange={handleChange("sepchoosedifferentdate")}
                                      className="form-text
                                      required
                                      form-control"                                             
                                  />                              
                              {/* <span className="input-group-addon"></span> */}
                            </div>
                          </span>
                        </div>
                        <div className="error">
                              <ErrorMessage errors={errors} name="sepchoosedifferentdate">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                  ))
                                }
                              </ErrorMessage>
                        </div> 
                      </div>
                      {/* Emergency/Natural Disaster */}
                      <div
                        className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-sepemergency
                                  js-form-item-sepemergency
                                  checkbox
                                "
                      >
                        <label
                          htmlFor="edit-sepemergency"
                          className="control-label option"
                        >
                          <input
                            {...register("sepemergency")}
                            value={state.enrollData.sepemergency}
                            onChange={handleEmergency}
                            data-drupal-selector="edit-sepemergency"
                            className="form-checkbox"
                            data-msg-required="I was affected by a weather-related emergency or major disaster (as declared by the Federal Emergency Management Agency (FEMA)). One of the other statements here applied to me, but I was unable to make my enrollment because of the natural disaster. is required."
                            type="checkbox"
                            id="edit-sepemergency"
                            {...(state.enrollData.emergency && {
                              checked: true,
                            })}
                          />
                          I was affected by an emergency or major disaster (as
                          declared by the Federal Emergency Management Agency
                          (FEMA) or by a federal, state or local government
                          entity). One of the other statements here applied to
                          me, but I was unable to make my enrollment request
                          because of the disaster.
                        </label>
                      </div>
                      {/* Incarceration */}
                      <div
                        className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-sepincarceration
                                  js-form-item-sepincarceration
                                  checkbox
                                "
                      >
                        <label
                          htmlFor="edit-sepincarceration"
                          className="control-label option"
                        >
                          <input
                            {...register("sepincarceration")}
                            value={state.enrollData.sepincarceration}
                            onChange={handleIncarceration}
                            data-drupal-selector="edit-sepincarceration"
                            className="form-checkbox"
                            data-msg-required="I was recently released from incarceration is required."
                            type="checkbox"
                            id="edit-sepincarceration"
                            {...(state.enrollData.incarceration && {
                              checked: true,
                            })}
                          />
                          I was recently released from incarceration.
                        </label>
                      </div>
                      <div
                        className="
                                  webform-has-field-prefix
                                  webform-has-field-suffix
                                  js-webform-states-hidden
                                  form-inline form-item
                                  js-form-item
                                  form-type-date
                                  js-form-type-date
                                  form-item-sepincarcerationdate
                                  js-form-item-sepincarcerationdate
                                  form-group
                                "
                        {...(!state.enrollData.incarceration && {
                          style: { display: "none" },
                        })}
                      >
                        <label
                          htmlFor="edit-sepincarcerationdate"
                          className="control-label"
                        >
                          I was released on
                        </label>

                        <div className="input-group">
                          <span className="input-group-addon">
                            <div className="date_wrap_div">
                              <CSDateInput
                                      name="sepincarcerationdate"
                                      control={control}
                                      rules={{ 
                                        validate: {
                                          requiredValidation:  value => { if (state.enrollData.incarceration && !value) return "I was released on is required."; return true }
                                        }
                                      }}
                                      value={state.enrollData.sepincarcerationdate}
                                      onChange={handleChange("sepincarcerationdate")}
                                      className="form-text
                                      required
                                      form-control"                                             
                                  />                            
                              {/* <span className="input-group-addon"></span> */}
                            </div>
                          </span>
                        </div>
                        <div className="error">
                              <ErrorMessage errors={errors} name="sepincarcerationdate">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                  ))
                                }
                              </ErrorMessage>
                        </div> 
                      </div>
                      {/* Other */}
                      <div
                        className="
                                  form-item
                                  js-form-item
                                  form-type-checkbox
                                  js-form-type-checkbox
                                  form-item-sepother
                                  js-form-item-sepother
                                  checkbox
                                "
                      >
                        <label
                          htmlFor="edit-sepother"
                          className="control-label option"
                        >
                          <input
                            {...register("sepother")}
                            value={state.enrollData.sepother}
                            onChange={handleOther}
                            data-drupal-selector="edit-sepother"
                            className="form-checkbox"
                            data-msg-required="Other special enrollment period not identified above is required."
                            type="checkbox"
                            id="edit-sepother"
                            {...(state.enrollData.other && { checked: true })}
                          />
                          Other special enrollment period not identified above.
                        </label>
                      </div>
                      <div
                        className="
                                  js-webform-states-hidden
                                  form-item
                                  js-form-item
                                  form-type-textfield
                                  js-form-type-textfield
                                  form-item-sepothertxt
                                  js-form-item-sepothertxt
                                  form-group
                                "
                        {...(!state.enrollData.other && {
                          style: { display: "none" },
                        })}
                      >
                        <label
                          htmlFor="edit-sepothertxt"
                          className="control-label"
                        >
                          Reason
                        </label>

                        <input
                          {...register("sepothertxt", {
                            validate: {
                              requiredValidation:  value => { if (state.enrollData.other && !value) return "Reason is required."; return true }
                            }}
                          )}
                          value={state.enrollData.sepothertxt}
                          onChange={handleChange("sepothertxt")}
                          pattern="^[-a-zA-Z0-9 ]*$"
                          data-webform-pattern-error="Special characters are not allowed."
                          data-drupal-selector="edit-sepothertxt"
                          className="form-text form-control"
                          data-msg-maxlength="Reason field has a maximum length of 255."
                          data-msg-required="Reason is required."
                          data-msg-pattern="Special characters are not allowed."
                          type="text"
                          id="edit-sepothertxt"
                          //name="sepothertxt"
                          //value=""
                          size="60"
                          maxLength="255"
                          data-drupal-states='{"visible":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022sepother\u0022]":{"checked":true}},"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022sepother\u0022]":{"checked":true}}}'
                        />
                        <div className="error">
                              <ErrorMessage errors={errors} name="sepothertxt">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                  ))
                                }
                              </ErrorMessage>
                        </div> 
                      </div>

                      <div
                        id="edit-no-statements-apply"
                        className="
                              form-item
                              js-form-item
                              form-type-processed-text
                              js-form-type-processed-text
                              form-item-
                              js-form-item-
                              form-no-label form-group
                            "
                      >
                        <div
                          className="
                                form-item
                                webform-component webform-component-markup
                              "
                          id="webform-component-no-statements-apply"
                        >
                          <p>
                            If none of these statements apply to you or you're
                            not sure, please contact a MedicareBlue Rx Medicare
                            Solutions specialist at{" "}
                            <strong>
                              <a href="tel:1-866-434-2037">1-866-434-2037</a>{" "}
                            </strong>
                            (TTY:{" "}
                            <strong>
                              <a href="tel:711">711</a>
                            </strong>
                            ), 8 a.m. to 8 p.m., daily, Central and Mountain
                            times to see if you are eligible to enroll.
                          </p>

                          <p>
                            Please contact us at{" "}
                            <strong>
                              <a href="tel:1-866-434-2037">1-866-434-2037</a>{" "}
                            </strong>
                            (TTY:{" "}
                            <strong>
                              <a href="tel:711">711</a>
                            </strong>
                            ) if you would prefer that we send you information
                            in a language other than English or in an accessible
                            format. Our office hours are 8 a.m. to 8 p.m.,
                            daily, Central and Mountain times.
                          </p>
                        </div>
                      </div>
                      <div
                        data-drupal-selector="edit-actions"
                        className="
                            form-actions
                            webform-actions
                            form-group
                            js-form-wrapper
                            form-wrapper
                          "
                        data-msg-required="Submit button(s) is required."
                        id="edit-actions"
                      >
                        <Button
                          className="
                            webform-button--previous
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                          onClick={handlePrevious}
                          //disabled={p1}
                          {...(p1 && {
                            style: { display: "none" },
                          })}
                        >
                          Previous
                        </Button>

                        <Button
                          className="
                            webform-button--next
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                          onClick={handleNextClick}
                          disabled={
                            activeStep === 5 &&
                            !state.enrollData.acceptTerms &&
                            isValid
                          }
                        >
                          {activeStep === steps.length - 1
                            ? "Finish"
                            : activeStep === steps.length - 2
                            ? "Submit"
                            : "Continue to " + steps[activeStep + 1].toString()}
                        </Button>
                        <div
                          className="error"
                          {...(displayCheckOptionError !== true && {
                            style: { display: "none" },
                          })}
                        >
                          At least one item must be selected.
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </article>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Step2_2025;
