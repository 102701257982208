import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { Button } from "@material-ui/core";
import moment from "moment";
import updateEnrollData from "../../../../state/actions/updateEnrollData";
import axios from "axios";
import { submitEnrollment } from "../../../../api/enrollmentApi";
import { browserName, browserVersion } from "react-device-detect";
import { getGenderText, getSexualOrientationText } from "../../../../utility/helpers";
import { getTelemetry } from "../../../../utility/telemetry";

const ReviewEnrollment_2025 = ({
  handleNext,
  handlePrevious,
  handleJumpTo,
  activeStep,
  steps,
  setBackButtonDisabled
}) => {
  const { state, actions } = useStateMachine({ updateEnrollData });

  let p1 = activeStep === 0 ? true : false;

  let submitDateTime = "";

  const getIpAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");

    let ip = JSON.stringify(res.data.IPv4);

    // console.log(ip);

    return ip;
  };

  const [isProcessing, setIsProcessing] = useState(false);

  const handleNextClick = async () => {
    
    setIsProcessing(true);
    setBackButtonDisabled(true);

    submitDateTime = moment().format("MM/DD/YYYY h:mm:ss a");
    // console.log(submitDateTime);

    let f = state.enrollData;

    f.dor = submitDateTime;
    f.timestamp = submitDateTime;
    f.ipaddress = getIpAddress();
    f.browsername = browserName;
    f.browserversion = browserVersion;
    f.telemetry = getTelemetry();

    let s = await submitEnrollment(f);

    let o = JSON.parse(s);

    f.confirmationno = o.confirmationno;

    console.log("Conf Num: " + o.confirmationno);

    //let hash = CryptoJS.MD5(f);

    // console.log("Return obj: " + s);
    // console.log("Source MD5: " + hash);
    // console.log("AWS MD5: " + o.MD5OfMessageBody);
    // console.log("MD5 Match: " + o.MD5OfMessageBody === hash);

    // console.log("Submit enrollment success: " + JSON.stringify(s));
    // console.log("Confirmation: " + o.confirmationno);

    if (s.confirmationno !== "") {
      actions.updateEnrollData({ confirmationno: o.confirmationno }); // <-- for conf screen display
      handleNext();
    } else {
      //Create and display error screen component here...
    }

    setIsProcessing(false);
  };

  const getPaymentOptionText = () => {
    switch (state.enrollData.paymentoption) {
      case "Paper":
        return "Get a paper bill";
      case "EFT":
        return "Electronic funds transfer (EFT)";
      case "SSA":
        return "Automatic deduction from your Social Security benefit check";
      case "RRB":
        return "Automatic deduction from your Railroad Retirement Board (RRB) benefit check";
      default:
        return "";
    }
  };

  const getPreparerText = () => {
    switch (state.enrollData.preparer) {
      case "enrollee":
        return "I am the enrollee";
      case "authRep":
        return "I am the enrollee's authorized representative";
      case "assisted":
        return "I assisted the enrollee in filling out the form";
      case "agent":
        return "I am the enrollee's agent";
      default:
        return "";
    }
  };



  // console.log("Review Preparer: " + state.enrollData.preparer);

  return (
    <div>
      <div
        className="
                  field
                  field--name-webform
                  field--type-webform
                  field--label-hidden
                  field__item
                "
      >
        <div
          className="
                    webform-submission-form
                    webform-submission-add-form
                    webform-submission-mbrx-enroll-2021-form
                    webform-submission-mbrx-enroll-2021-add-form
                    webform-submission-mbrx-enroll-2021-node-234-form
                    webform-submission-mbrx-enroll-2021-node-234-add-form
                    js-webform-disable-autosubmit js-webform-details-toggle
                    webform-details-toggle
                  "
          data-drupal-selector="webform-submission-mbrx-enroll-2021-node-234-add-form"
          data-msg-required="This field is required."
          action="/mbrx-enroll-2021"
          method="post"
          id="webform-submission-mbrx-enroll-2021-node-234-add-form"
          acceptCharset="UTF-8"
          noValidate="noValidate"
          data-drupal-form-fields="edit-step-1,edit-step-2,edit-step-3,edit-step-4,edit-agree-terms-enroll-auth,edit-datesigned,edit-signaturefirst,edit-signaturelast,edit-preparer-enrollee,edit-preparer-auth-rep,edit-preparer-assisted,edit-preparer-agent,edit-callcenter,edit-assistorsigned,edit-assistorrelationship2applicant,edit-assistorsigneddate,edit-agentfirst,edit-agentlast,edit-agentid,edit-enrollee-perm-state,edit-agencyid,edit-agentphone,edit-callcentername,edit-callcenterrep,edit-ridnbr,edit-please-specify-one-present,edit-please-specify-one-attestation,edit-datesignedpaper,edit-agentsigned,edit-has-authrep-info,edit-authrepfirstname,edit-authreplastname,edit-authreprelationship,edit-authrepstreet,edit-authrepcity,edit-authrepstate,edit-authrepzip,edit-authrepphone,edit-authrepmail,edit-accessibleformatoption-braille,edit-accessibleformatoption-large-print,edit-accessibleformatoption-audio-cd,edit-actions-wizard-prev,edit-actions-wizard-next"
        >
          <div
            data-webform-key="step_5"
            data-drupal-selector="edit-step-5"
            className="js-form-wrapper form-wrapper form-group"
            data-msg-required="Step 5 is required."
            id="edit-step-5--2"
          >
            <div
              id="edit-step-5-title"
              className="
                        form-item
                        js-form-item
                        form-type-processed-text
                        js-form-type-processed-text
                        form-item-
                        js-form-item-
                        form-no-label form-group
                      "
            >
              <h2>Review Enrollment</h2>
            </div>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

            <div>
              <div
                className="dialog-off-canvas-main-canvas"
                data-off-canvas-main-canvas=""
              >
                <div
                  role="main"
                  className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
                ></div>
                <div className="row d-md-flex">
                  <section className="col-sm-12 internal-page">
                    <div className="region region-content">
                      <article className="webform full clearfix">
                        <div
                          className="
                        webform-submission-form
                        webform-submission-add-form                        
                        webform-details-toggle
                      "
                        >
                          <div className="js-form-wrapper form-wrapper form-group">
                            <div
                              className="
                            form-item
                            js-form-item
                            form-wrapper
                            js-form-wrapper
                            panel panel-default
                          "
                            >
                              {/*Row 1*/}
                              <div
                                className="row form-group js-form-wrapper form-wrapper"
                                data-drupal-selector="edit-row-1-container"
                                data-msg-required="This field is required."
                                id="edit-row-1-container"
                              >
                                {/*Product Selection*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Product selection
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(0);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <label className="control-label">
                                      Choose a plan:{" "}
                                    </label>{" "}
                                    {state.enrollData.planname}
                                  </div>
                                </div>

                                {/*Personal Information*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Personal information
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(0);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <div
                                      {...(state.enrollData.sal === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Title:
                                      </label>{" "}
                                      {state.enrollData.sal}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.firstname ===
                                        "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        First name:{" "}
                                      </label>{" "}
                                      {state.enrollData.firstname}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.mi === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Middle initial:
                                      </label>{" "}
                                      {state.enrollData.mi}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.lastname === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Last name:
                                      </label>{" "}
                                      {state.enrollData.lastname}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.dob === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Date of birth:
                                      </label>{" "}
                                      {state.enrollData.dob}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.sex === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Gender:
                                      </label>{" "}
                                      {state.enrollData.sex}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.permst1 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Address:
                                      </label>{" "}
                                      {state.enrollData.permst1}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.permst2 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Address 2
                                      </label>{" "}
                                      {state.enrollData.permst2}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.permcity === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        City:
                                      </label>{" "}
                                      {state.enrollData.permcity}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.permstate ===
                                        "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        State:
                                      </label>{" "}
                                      {state.enrollData.permstate}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.permzip5 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Zip code (xxxxx):
                                      </label>{" "}
                                      {state.enrollData.permzip5}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.permzip4 === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Zip+4 (xxxx)
                                      </label>{" "}
                                      {state.enrollData.permzip4}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.homephone ===
                                        "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Home phone:
                                      </label>{" "}
                                      {state.enrollData.homephone}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.altphone === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Alternate phone:
                                      </label>{" "}
                                      {state.enrollData.altphone}
                                      <br />
                                    </div>
                                    <div
                                      {...(state.enrollData.email === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Email address
                                      </label>{" "}
                                      {state.enrollData.email}
                                      <br />
                                    </div>
                                    <div
                                      {...(!state.enrollData.diffAddress && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <div
                                        {...(state.enrollData.mailst1 ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Mailing Address:
                                        </label>{" "}
                                        {state.enrollData.mailst1}
                                        <br />
                                      </div>
                                      <div
                                        {...(state.enrollData.mailst2 ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Mailing Address 2:
                                        </label>{" "}
                                        {state.enrollData.mailst2}
                                        <br />
                                      </div>
                                      <div
                                        {...(state.enrollData.mailcity ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Mailing City:
                                        </label>{" "}
                                        {state.enrollData.mailcity}
                                        <br />
                                      </div>
                                      <div
                                        {...(state.enrollData.mailstate ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Mailing State:
                                        </label>{" "}
                                        {state.enrollData.mailstate}
                                        <br />
                                      </div>
                                      <div
                                        {...(state.enrollData.mailzip5 ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Mailing Zip Code (xxxxx):
                                        </label>{" "}
                                        {state.enrollData.mailzip5}
                                        <br />
                                      </div>
                                      <div
                                        {...(state.enrollData.mailzip4 ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Mailing Zip+4:
                                        </label>{" "}
                                        {state.enrollData.mailzip4}
                                        <br />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/*Row 2*/}
                              <div
                                className="row form-group js-form-wrapper form-wrapper"
                                data-drupal-selector="edit-row-1-container"
                                data-msg-required="This field is required."
                                id="edit-row-1-container"
                              >
                                {/*Medicare Information*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Medicare information
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(0);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <label className="control-label">
                                      Medicare claim (ID) number:{" "}
                                    </label>{" "}
                                    {state.enrollData.medicareclaimnum}
                                  </div>
                                </div>

                                {/*Additional Questions*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Additional questions
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(3);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <label className="control-label">
                                      Will you have other prescription drug
                                      coverage in addition to MedicareBlue Rx?:
                                    </label>{" "}
                                    {state.enrollData.adddrugcov === "Y"
                                      ? "Yes"
                                      : "No"}
                                    <div
                                      {...(state.enrollData.adddrugcov !==
                                        "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <div
                                        {...(state.enrollData.addcovname ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Name of other coverage
                                        </label>{" "}
                                        {state.enrollData.addcovname}
                                        <br />
                                      </div>

                                      <div
                                        {...(state.enrollData.addcovid ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Member number for this coverage
                                        </label>{" "}
                                        {state.enrollData.addcovid}
                                        <br />
                                      </div>

                                      <div
                                        {...(state.enrollData.addgroupid ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Group number for this coverage
                                        </label>{" "}
                                        {state.enrollData.addgroupid}
                                        <br />
                                      </div>

                                      <div
                                        {...(state.enrollData.instname ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Name of facility
                                        </label>{" "}
                                        {state.enrollData.instname}
                                        <br />
                                      </div>

                                      <div
                                        {...(state.enrollData.instadd ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Street address:
                                        </label>{" "}
                                        {state.enrollData.instadd}
                                        <br />
                                      </div>
                                      <label className="control-label">
                                        City:
                                      </label>

                                      <div
                                        {...(state.enrollData.inststate ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          State:
                                        </label>{" "}
                                        {state.enrollData.inststate}
                                        <br />
                                      </div>

                                      <div
                                        {...(state.enrollData.instzip ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Zip:
                                        </label>{" "}
                                        {state.enrollData.instzip}
                                        <br />
                                      </div>
                                      <div
                                        {...(state.enrollData.instphone ===
                                          "" && {
                                          style: { display: "none" },
                                        })}
                                      >
                                        <label className="control-label">
                                          Phone:
                                        </label>{" "}
                                        {state.enrollData.instphone}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/*Row 3*/}
                              <div
                                className="row form-group js-form-wrapper form-wrapper"
                                data-drupal-selector="edit-row-1-container"
                                data-msg-required="This field is required."
                                id="edit-row-1-container"
                              >
                                {/*Enrollment Period Determination*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Enrollment period determination
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(1);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    {/*AEP*/}
                                    {/* <div
                                      {...(state.enrollData.aep !== "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I am enrolling during the annual
                                        enrollment period, October 15 through
                                        December 7, for a January 1, 2025
                                        effective date. :
                                      </label>{" "}
                                      {state.enrollData.aep === "Y"
                                        ? "Yes"
                                        : "No"}
                                    </div> */}

                                    <div
                                      {...(state.enrollData.sepnewtomedicare !==
                                        "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I am new to Medicare:{" "}
                                      </label>{" "}
                                      {state.enrollData.sepnewtomedicare === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        Part A date:{" "}
                                      </label>
                                      {state.enrollData.partadate}
                                      <br />
                                      <label className="control-label">
                                        Part b date:{" "}
                                      </label>
                                      {state.enrollData.partbdate}
                                    </div>

                                    <div
                                      {...(state.enrollData
                                        .sepserviceareamove === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I recently moved outside of the service
                                        area for my current plan or I recently
                                        moved and this plan is a new option for
                                        me. :
                                      </label>{" "}
                                      {state.enrollData.sepserviceareamove ===
                                      "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        I moved on:
                                      </label>{" "}
                                      {state.enrollData.sepserviceareamovedate}
                                      <br />
                                      <label className="control-label">
                                        Requested effective date:
                                      </label>{" "}
                                      {state.enrollData.effectivedate}
                                    </div>
                                    <br />
                                    <div
                                      {...(!state.enrollData.ltc && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I live in or recently moved out of a
                                        long-term care facility :
                                      </label>{" "}
                                      {state.enrollData.sepltc === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        I moved/will move into/out of the
                                        facility on
                                      </label>{" "}
                                      {state.enrollData.sepltcdate}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData.sepreturn2us !==
                                        "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I recently returned to the United States
                                        after living permanently outside of t he
                                        U.S.:
                                      </label>{" "}
                                      {state.enrollData.sepreturn2us === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        I returned to the U.S. on:
                                      </label>{" "}
                                      {state.enrollData.sepreturn2usdate}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData.seplostcoverage !==
                                        "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I recently involuntarily lost my
                                        creditable prescription drug coverage
                                        (coverage as good as Medicare's) or was
                                        notified of the loss (whichever is
                                        later).:
                                      </label>{" "}
                                      {state.enrollData.seplostcoverage === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        I lost my drug coverage on:
                                      </label>{" "}
                                      {state.enrollData.seplostcoveragedate}
                                      <br />
                                      <label className="control-label">
                                        Requested effective date:
                                      </label>{" "}
                                      {state.enrollData.sepotherdate3}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData.sepdisenrlsnp !==
                                        "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I am being disenrolled from a special
                                        needs plan because my condition does not
                                        qualify me for that plan:
                                      </label>{" "}
                                      {state.enrollData.sepdisenrlsnp === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        Disenrollment date:
                                      </label>{" "}
                                      {state.enrollData.sepdisenrlsnpdate}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData.sepnonrenewal !==
                                        "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label>
                                        I am being disenrolled from my existing
                                        plan due to its non-renewal:
                                      </label>{" "}
                                      {state.enrollData.sepnonrenewal === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        Disrollment date
                                      </label>{" "}
                                      {state.enrollData.sepnonrenewaldate}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData.seplostpdppartb !==
                                        "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I have been disenrolled from a Medicare
                                        Advantage-Prescription Drug plan due to
                                        loss of Part B but continue to be
                                        entitled to Part A:
                                      </label>{" "}
                                      {state.enrollData.seplostpdppartb === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        Disenrollment date
                                      </label>{" "}
                                      {state.enrollData.seplostpdppartbdate}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData.sepmaoep !==
                                        "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I am enrolled in a Medicare Advantage
                                        plan and want to make a change during
                                        the Medicare Advantage Open Enrollment
                                        Period (MA OEP) of January 1 to March
                                        31.:
                                      </label>{" "}
                                      {state.enrollData.sepmaoep === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData
                                        .sepleavingempcoverage === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I am leaving employer or union coverage:
                                      </label>{" "}
                                      {state.enrollData
                                        .sepleavingempcoverage === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        Leave date
                                      </label>{" "}
                                      {
                                        state.enrollData
                                          .sepleavingempcoveragedate
                                      }
                                      <br />
                                      <label className="control-label">
                                        Requested effective date
                                      </label>{" "}
                                      {state.enrollData.sepotherdate2}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData.sepleavingma !==
                                        "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I am leaving my Medicare Advantage plan
                                        within 12 months of my initial
                                        enrollment under a special enrollment
                                        period to go back to a Medigap (Medicare
                                        supplement) plan:
                                      </label>{" "}
                                      {state.enrollData.sepleavingma === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        I left on:
                                      </label>{" "}
                                      {state.enrollData.sepleavingmadate}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData.sepdisenrollmcp !==
                                        "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I am leaving employer or union coverage:
                                      </label>{" "}
                                      {state.enrollData.sepdisenrollmcp === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        Leave date
                                      </label>{" "}
                                      {state.enrollData.sepdisenrollmcpdate}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData.sepextrahelp !==
                                        "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I recently had a change in my Extra Help
                                        paying for Medicare prescription drug
                                        coverage:
                                      </label>{" "}
                                      {state.enrollData.sepextrahelp === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        Date of change :
                                      </label>{" "}
                                      {state.enrollData.sepextrahelpdate}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData
                                        .sepmedicarepremcoverage === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I have both Medicare and Medicaid:
                                      </label>{" "}
                                      {state.enrollData
                                        .sepmedicarepremcoverage === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData.sepmedicaid !==
                                        "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I recently had a change in my Medicaid:
                                      </label>{" "}
                                      {state.enrollData.sepmedicaid === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        Date of change:
                                      </label>{" "}
                                      {state.enrollData.sepmedicaiddate}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData
                                        .seplawfulpresence === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I recently obtained lawful presence in
                                        the United States:
                                      </label>{" "}
                                      {state.enrollData.seplawfulpresence ===
                                      "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        I got this status on:
                                      </label>{" "}
                                      {state.enrollData.seplawfulpresencedate}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData
                                        .sepchoosedifferent === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I was enrolled in a plan by Medicare (or
                                        my state) and I want to choose a
                                        different plan:
                                      </label>{" "}
                                      {state.enrollData.sepchoosedifferent ===
                                      "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        My enrollment in that plan started on
                                      </label>{" "}
                                      {state.enrollData.sepchoosedifferentdate}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData.sepemergency !==
                                        "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I was affected by a weather-related
                                        emergency or major disaster (as declared
                                        by the Federal Emergency Management
                                        Agency (FEMA)).:
                                      </label>{" "}
                                      {state.enrollData.sepemergency === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                    </div>

                                    <div
                                      {...(state.enrollData.sepincarceration !==
                                        "Y" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        I was recently released from
                                        incarceration:
                                      </label>{" "}
                                      {state.enrollData.sepincarceration === "Y"
                                        ? "Yes"
                                        : "No"}
                                      <br />
                                      <label className="control-label">
                                        I was released on
                                      </label>{" "}
                                      {state.enrollData.sepincarcerationdate}
                                    </div>

                                    <div
                                      {...(!state.enrollData.other && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Other special enrollment period not
                                        identified above :
                                      </label>
                                      <label className="control-label">
                                        Reason:
                                      </label>{" "}
                                      {state.enrollData.sepothertxt}
                                    </div>

                                    {/* <div
                                      {...(values.sepnewtomedicare === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label>
                                        I belong to Big Sky Rx (a State
                                        pharmaceutical assistance program)
                                        provided by the state of Montana:
                                      </label>
                                    </div> */}
                                  </div>
                                </div>

                                {/*Premium Payment Option*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Premium payment option
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(2);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <label className="control-label">
                                      Payment options:{" "}
                                    </label>{" "}
                                    {getPaymentOptionText()}
                                    <br />
                                    <div
                                      {...(state.enrollData.paymentoption !==
                                        "EFT" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label className="control-label">
                                        Account holder name:
                                      </label>{" "}
                                      {state.enrollData.accountholdername}
                                      <br />
                                      <label className="control-label">
                                        Bank name:
                                      </label>{" "}
                                      {state.enrollData.bankname}
                                      <br />
                                      <label className="control-label">
                                        Account type:
                                      </label>{" "}
                                      {state.enrollData.accttype}
                                      <br />
                                      <label className="control-label">
                                        Bank routing number:
                                      </label>{" "}
                                      {state.enrollData.bankroutingnumber}
                                      <br />
                                      <label className="control-label">
                                        Bank account number:
                                      </label>{" "}
                                      {state.enrollData.bankacctnumber}
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/*Row 4*/}
                              <div
                                className="row form-group js-form-wrapper form-wrapper"
                                data-drupal-selector="edit-row-1-container"
                                data-msg-required="This field is required."
                                id="edit-row-1-container"
                              >
                                {/*Enrollment Authorization*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Enrollment authorization
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(4);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <label className="control-label">
                                      Enrollment authorization agreement:{" "}
                                    </label>{" "}
                                    {state.enrollData.acceptTerms
                                      ? "Yes"
                                      : "No"}
                                  </div>
                                </div>

                                {/*Applicant/Auth Rep Signature*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Applicant or authorized representative
                                      signature
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(4);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <div>
                                      <label className="control-label">
                                        Today's date:
                                      </label>{" "}
                                      {moment().format("MM/DD/YYYY")}
                                      <br />
                                    </div>

                                    <div>
                                      <label className="control-label">
                                        Enrollee first name:
                                      </label>{" "}
                                      {state.enrollData.signaturefirst}
                                      <br />
                                    </div>

                                    <div>
                                      <label className="control-label">
                                        Enrollee last name:
                                      </label>{" "}
                                      {state.enrollData.signaturelast}
                                      <br />
                                    </div>

                                    <div>
                                      <label className="control-label">
                                        Person completing document:
                                      </label>{" "}
                                      {getPreparerText()}
                                      <br />
                                    </div>
                                  </div>
                                  <div
                                    {...(!state.enrollData.callCenterAssist && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    <label className="control-label">
                                      Call center:
                                    </label>
                                    {"  "}
                                    {state.enrollData.callcentername}
                                    <br />

                                    <label className="control-label">
                                      Call center representative (first name
                                      last name):
                                    </label>
                                    {"  "}
                                    {state.enrollData.callcenterrep}
                                    <br />

                                    <label className="control-label">
                                      Call record number:
                                    </label>
                                    {"  "}
                                    {state.enrollData.ridnbr}
                                    <br />
                                  </div>

                                  <div
                                    {...(state.enrollData.preparer !==
                                      "authRep" && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    <label className="control-label">
                                      Representative first name:
                                    </label>{" "}
                                    {state.enrollData.authrepfirstname}
                                    <br />
                                    <label className="control-label">
                                      Representative last name:
                                    </label>{" "}
                                    {state.enrollData.authreplastname}
                                    <br />
                                    <label className="control-label">
                                      Relationship to enrollee:
                                    </label>{" "}
                                    {state.enrollData.authreprelationship}
                                    <br />
                                    <label className="control-label">
                                      Street address:
                                    </label>{" "}
                                    {state.enrollData.authrepstreet}
                                    <br />
                                    <label className="control-label">
                                      City:
                                    </label>{" "}
                                    {state.enrollData.authrepcity}
                                    <br />
                                    <label className="control-label">
                                      State:
                                    </label>{" "}
                                    {state.enrollData.authrepstate}
                                    <br />
                                    <label className="control-label">
                                      Zip code:
                                    </label>{" "}
                                    {state.enrollData.authrepzip}
                                    <br />
                                    <label className="control-label">
                                      Phone number:
                                    </label>{" "}
                                    {state.enrollData.authrepphone}
                                    <br />
                                    <label className="control-label">
                                      I want all of my information sent to this
                                      address.
                                    </label>{" "}
                                    {state.enrollData.authrepmail}
                                    <br />
                                  </div>
                                  <div
                                    {...(state.enrollData.preparer !==
                                      "assisted" && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    <label className="control-label">
                                      Assistor signed (First Name Last Name):
                                    </label>{" "}
                                    {state.enrollData.assistorsigned}
                                    <br />
                                    <label className="control-label">
                                      Relationship to enrollee:
                                    </label>{" "}
                                    {
                                      state.enrollData
                                        .assistorrelationship2applicant
                                    }
                                    <br />
                                    <label className="control-label">
                                      Assistor date signed
                                    </label>{" "}
                                    {state.enrollData.assistorsigneddate}
                                    <br />
                                  </div>
                                  <div
                                    {...(state.enrollData.preparer !==
                                      "agent" && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    <label className="control-label">
                                      Certified agent first name:
                                    </label>{" "}
                                    {state.enrollData.agentfirst}
                                    <br />
                                    <label className="control-label">
                                      Certified agent last name:
                                    </label>{" "}
                                    {state.enrollData.agentlast}
                                    <br />
                                    <label className="control-label">
                                      Agent ID (assigned by your Blue Cross Blue Shield plan):
                                    </label>{" "}
                                    {state.enrollData.agentid}
                                    <br />

                                    <label className="control-label">
                                        National Producer Number (NPN):
                                      </label>{" "}
                                      {state.enrollData.nationalproducernumber}
                                    <br />

                                    <label className="control-label">
                                      Agency ID (if used and assigned by your Blue Cross Blue Shield plan):
                                    </label>{" "}
                                    {state.enrollData.agencyid}
                                    <br />
                                    <label className="control-label">
                                      Agent phone number:
                                    </label>{" "}
                                    {state.enrollData.agentphone}
                                    <br />
                                    <label className="control-label">
                                      Please specify whether enrollee is present
                                      or if Agent has paper enrollment
                                      (attestation) in hand:
                                    </label>{" "}
                                    {state.enrollData.agentSignedPaper ===
                                    "attestation"
                                      ? "Agent has paper enrollment (attestation) in hand"
                                      : state.enrollData.agentSignedPaper ===
                                        "present"
                                      ? "Enrollee is present"
                                      : ""}
                                    <br />
                                    <label className="control-label">
                                      What date did the enrollee sign the paper
                                      enrollment form:
                                    </label>{" "}
                                    {state.enrollData.datesignedpaper}
                                    <br />
                                    <label className="control-label">
                                      What date did you, the agent, sign the
                                      paper enrollment form
                                    </label>{" "}
                                    {state.enrollData.agentsigned}
                                    <br />
                                  </div>
                                  {/* <label className="control-label">
                                        I am a call agent:
                                      </label> */}
                                </div>
                              </div>
                              {/*Row 5*/}
                              <div
                                className="row form-group js-form-wrapper form-wrapper"
                                data-drupal-selector="edit-row-1-container"
                                data-msg-required="This field is required."
                                id="edit-row-1-container"
                              >
                                {/*Empty Section*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                ></div>

                                {/*Accessible Format Selection*/}
                                <div
                                  className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                  data-drupal-selector="edit-review-information"
                                  data-msg-required="Product selection is required."
                                  id="edit-review-information"
                                >
                                  <div className="panel-heading">
                                    <div className="panel-title collapse-link">
                                      Accessible format selection
                                    </div>
                                  </div>
                                  <Button
                                    className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                    data-webform-wizard-page="step_1"
                                    id="edit-wizard-page-step_1_1"
                                    data-drupal-selector="edit-0"
                                    data-msg-required="This field is required."
                                    type="submit"
                                    name="step_1_1"
                                    value="Edit"
                                    formnovalidate="formnovalidate"
                                    onClick={() => {
                                      handleJumpTo(4);
                                    }}
                                  >
                                    <span
                                      className="icon glyphicon glyphicon-pencil"
                                      aria-hidden="true"
                                    ></span>
                                    Edit
                                  </Button>
                                  <div className="panel-body">
                                    <label className="control-label">
                                      Accessible format options:{" "}
                                    </label>{" "}
                                    <div
                                      {...(!state.enrollData.braille && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      Braille
                                    </div>
                                    <div
                                      {...(!state.enrollData.largePrint && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      Large Print
                                    </div>
                                    <div
                                      {...(!state.enrollData.cd && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      Audio CD
                                    </div>
                                    <div
                                      {...(!state.enrollData.dataCD && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      Data CD
                                    </div>
                                  </div>
                                </div>
                              </div>


                                                          {/*Row 6*/}
                            {/* <div
                              className="row form-group js-form-wrapper form-wrapper"
                              data-drupal-selector="edit-row-1-container"
                              data-msg-required="This field is required."
                              id="edit-row-1-container"
                            >                              
                              <div
                                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                data-drupal-selector="edit-review-ethnicity-information"
                                data-msg-required="Ethnicity selection is required."
                                id="edit-review-ethnicity-information"
                              >
                                <div className="panel-heading">
                                  <div className="panel-title collapse-link">
                                    Ethnicity selection
                                  </div>
                                </div>
                                <Button
                                  className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                  data-webform-wizard-page="step_1"
                                  id="edit-wizard-page-step_1_1"
                                  data-drupal-selector="edit-0"
                                  data-msg-required="This field is required."
                                  type="submit"
                                  name="step_1_1"
                                  value="Edit"
                                  formnovalidate="formnovalidate"
                                  onClick={() => {
                                    handleJumpTo(4);
                                  }}
                                >
                                  <span
                                    className="icon glyphicon glyphicon-pencil"
                                    aria-hidden="true"
                                  ></span>
                                  Edit
                                </Button>
                                <div className="panel-body">
                                  <label className="control-label">
                                    Ethnicity options:{" "}
                                  </label>{" "}
                                  <div
                                    {...(!state.enrollData.ethnicityNA && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    No, not of Hispanic, Latino/a, or Spanish origin
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityMX && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Yes, Mexican, Mexican American, Chicano/a
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityPR && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Yes, Puerto Rican
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityCUB && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Yes, Cuban
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityOTH && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Yes, another Hispanic, Latino/a, or Spanish origin
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityNR && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    I choose not to answer.
                                  </div>

                                </div>
                              </div>
                              
                              <div
                                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                data-drupal-selector="edit-review-race-information"
                                data-msg-required="Race selection is required."
                                id="edit-review-race-information"
                              >
                                <div className="panel-heading">
                                  <div className="panel-title collapse-link">
                                    Race selection
                                  </div>
                                </div>
                                <Button
                                  className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                  data-webform-wizard-page="step_1"
                                  id="edit-wizard-page-step_1_1"
                                  data-drupal-selector="edit-0"
                                  data-msg-required="This field is required."
                                  type="submit"
                                  name="step_1_1"
                                  value="Edit"
                                  formnovalidate="formnovalidate"
                                  onClick={() => {
                                    handleJumpTo(4);
                                  }}
                                >
                                  <span
                                    className="icon glyphicon glyphicon-pencil"
                                    aria-hidden="true"
                                  ></span>
                                  Edit
                                </Button>
                                <div className="panel-body">
                                  <label className="control-label">
                                    Race options:{" "}
                                  </label>{" "}
                                  <div
                                    {...(!state.enrollData.race300 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    American Indian or Alaska Native
                                  </div>
                                  <div
                                    {...(!state.enrollData.race401 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Asian Indian
                                  </div>
                                  <div
                                    {...(!state.enrollData.race201 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Black or African American
                                  </div>
                                  <div
                                    {...(!state.enrollData.race411 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Chinese
                                  </div>
                                  <div
                                    {...(!state.enrollData.race421 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Filipino
                                  </div>
                                  <div
                                    {...(!state.enrollData.race521 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Guamanian or Chamorro
                                  </div>

                                  <div
                                    {...(!state.enrollData.race431 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Japanese
                                  </div>
                                  <div
                                    {...(!state.enrollData.race441 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Korean
                                  </div>
                                  <div
                                    {...(!state.enrollData.race501 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Native Hawaiian
                                  </div>
                                  <div
                                    {...(!state.enrollData.race499 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Other Asian
                                  </div>
                                  <div
                                    {...(!state.enrollData.race599 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Other Pacific Islander
                                  </div>
                                  <div
                                    {...(!state.enrollData.race511 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Samoan
                                  </div>
                                  <div
                                    {...(!state.enrollData.race451 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Vietnamese
                                  </div>
                                  <div
                                    {...(!state.enrollData.race101 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   White
                                  </div>
                                  <div
                                    {...(!state.enrollData.race999 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   I choose not to answer
                                  </div>

                                </div>
                              </div>
                            </div> */}


                            {/*Row 6*/}
                            <div
                              className="row form-group js-form-wrapper form-wrapper"
                              data-drupal-selector="edit-row-1-container"
                              data-msg-required="This field is required."
                              id="edit-row-1-container"
                            >
                              {/*Ethnicity Section*/}
                              <div
                                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                data-drupal-selector="edit-review-ethnicity-information"
                                data-msg-required="Ethnicity selection is required."
                                id="edit-review-ethnicity-information"
                              >
                                <div className="panel-heading">
                                  <div className="panel-title collapse-link">
                                    Ethnicity selection
                                  </div>
                                </div>
                                <Button
                                  className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                  data-webform-wizard-page="step_1"
                                  id="edit-wizard-page-step_1_1"
                                  data-drupal-selector="edit-0"
                                  data-msg-required="This field is required."
                                  type="submit"
                                  name="step_1_1"
                                  value="Edit"
                                  formnovalidate="formnovalidate"
                                  onClick={() => {
                                    handleJumpTo(4);
                                  }}
                                >
                                  <span
                                    className="icon glyphicon glyphicon-pencil"
                                    aria-hidden="true"
                                  ></span>
                                  Edit
                                </Button>
                                <div className="panel-body">
                                  <label className="control-label">
                                    Ethnicity options:{" "}
                                  </label>{" "}
                                  <div
                                    {...(!state.enrollData.ethnicityNA && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    No, not of Hispanic, Latino/a, or Spanish origin
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityMX && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Yes, Mexican, Mexican American, Chicano/a
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityPR && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Yes, Puerto Rican
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityCUB && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Yes, Cuban
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityOTH && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Yes, another Hispanic, Latino/a, or Spanish origin
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityNR && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    I choose not to answer.
                                  </div>

                                </div>
                              </div>

                              {/*Race Selection*/}
                              <div
                                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                data-drupal-selector="edit-review-race-information"
                                data-msg-required="Race selection is required."
                                id="edit-review-race-information"
                              >
                                <div className="panel-heading">
                                  <div className="panel-title collapse-link">
                                    Race selection
                                  </div>
                                </div>
                                <Button
                                  className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                  data-webform-wizard-page="step_1"
                                  id="edit-wizard-page-step_1_1"
                                  data-drupal-selector="edit-0"
                                  data-msg-required="This field is required."
                                  type="submit"
                                  name="step_1_1"
                                  value="Edit"
                                  formnovalidate="formnovalidate"
                                  onClick={() => {
                                    handleJumpTo(4);
                                  }}
                                >
                                  <span
                                    className="icon glyphicon glyphicon-pencil"
                                    aria-hidden="true"
                                  ></span>
                                  Edit
                                </Button>
                                <div className="panel-body">
                                  <label className="control-label">
                                    Race options:{" "}
                                  </label>{" "}
                                  <div
                                    {...(!state.enrollData.race300 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    American Indian or Alaska Native
                                  </div>
                                  <div
                                    {...(!state.enrollData.race401 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Asian Indian
                                  </div>
                                  <div
                                    {...(!state.enrollData.race201 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Black or African American
                                  </div>
                                  <div
                                    {...(!state.enrollData.race411 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Chinese
                                  </div>
                                  <div
                                    {...(!state.enrollData.race421 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Filipino
                                  </div>
                                  <div
                                    {...(!state.enrollData.race521 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Guamanian or Chamorro
                                  </div>

                                  <div
                                    {...(!state.enrollData.race431 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Japanese
                                  </div>
                                  <div
                                    {...(!state.enrollData.race441 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Korean
                                  </div>
                                  <div
                                    {...(!state.enrollData.race501 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Native Hawaiian
                                  </div>
                                  <div
                                    {...(!state.enrollData.race499 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Other Asian
                                  </div>
                                  <div
                                    {...(!state.enrollData.race599 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Other Pacific Islander
                                  </div>
                                  <div
                                    {...(!state.enrollData.race511 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Samoan
                                  </div>
                                  <div
                                    {...(!state.enrollData.race451 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Vietnamese
                                  </div>
                                  <div
                                    {...(!state.enrollData.race101 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   White
                                  </div>
                                  <div
                                    {...(!state.enrollData.race999 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   I choose not to answer
                                  </div>

                                </div>
                              </div>
                            </div>


                            {/*Row 7*/}
                            <div
                              className="row form-group js-form-wrapper form-wrapper"
                              data-drupal-selector="edit-row-1-container"
                              data-msg-required="This field is required."
                              id="edit-row-1-container"
                            >

                              {/*Sex assigned at birth selection*/}
                              {/* <div
                                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                // data-drupal-selector="edit-review-race-information"
                                // data-msg-required="Race selection is required."
                                id="edit-review-sex-information"
                              >
                                <div className="panel-heading">
                                  <div className="panel-title collapse-link">
                                  Sex assigned at birth selection
                                  </div>
                                </div>
                                <Button
                                  className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                  data-webform-wizard-page="step_1"
                                  id="edit-wizard-page-step_1_1"
                                  data-drupal-selector="edit-0"
                                  data-msg-required="This field is required."
                                  type="submit"
                                  name="step_1_1"
                                  value="Edit"
                                  formnovalidate="formnovalidate"
                                  onClick={() => {
                                    handleJumpTo(5);
                                  }}
                                >
                                  <span
                                    className="icon glyphicon glyphicon-pencil"
                                    aria-hidden="true"
                                  ></span>
                                  Edit
                                </Button>
                                <div className="panel-body">
                                  <div
                                    {...(!state.enrollData.sexassignedatbirth && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    {state.enrollData.sexassignedatbirth}
                                  </div>  
                                </div>
                                
                              </div>  */}

                              {/*Gender identity selection*/}
                              <div
                                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                // data-drupal-selector="edit-review-race-information"
                                // data-msg-required="Race selection is required."
                                id="edit-review-gender-identity"
                              >
                                <div className="panel-heading">
                                  <div className="panel-title collapse-link">
                                  Gender selection
                                  </div>
                                </div>
                                <Button
                                  className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                  data-webform-wizard-page="step_1"
                                  id="edit-wizard-page-step_1_1"
                                  data-drupal-selector="edit-0"
                                  data-msg-required="This field is required."
                                  type="submit"
                                  name="step_1_1"
                                  value="Edit"
                                  formnovalidate="formnovalidate"
                                  onClick={() => {
                                    handleJumpTo(4);
                                  }}
                                >
                                  <span
                                    className="icon glyphicon glyphicon-pencil"
                                    aria-hidden="true"
                                  ></span>
                                  Edit
                                </Button>
                                <div className="panel-body">
                                  {/* <label className="control-label">
                                    Race options:{" "}
                                  </label>{" "} */}
                                  <div
                                    {...(!state.enrollData.genderidentity && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    {getGenderText(state.enrollData.genderidentity)}
                                  </div>  
                                  <div
                                    {...(state.enrollData.genderidentity != "4" && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    {state.enrollData.genderidentitytext}
                                  </div> 
                                </div>
                                
                              </div>

                              {/*Sexual orientation selection*/}
                              <div
                                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                // data-drupal-selector="edit-review-race-information"
                                // data-msg-required="Race selection is required."
                                id="edit-review-sex-information"
                              >
                                <div className="panel-heading">
                                  <div className="panel-title collapse-link">
                                  How you think of yourself selection
                                  </div>
                                </div>
                                <Button
                                  className="js-webform-novalidate button js-form-submit form-submit btn-default btn icon-before"
                                  data-webform-wizard-page="step_1"
                                  id="edit-wizard-page-step_1_1"
                                  data-drupal-selector="edit-0"
                                  data-msg-required="This field is required."
                                  type="submit"
                                  name="step_1_1"
                                  value="Edit"
                                  formnovalidate="formnovalidate"
                                  onClick={() => {
                                    handleJumpTo(4);
                                  }}
                                >
                                  <span
                                    className="icon glyphicon glyphicon-pencil"
                                    aria-hidden="true"
                                  ></span>
                                  Edit
                                </Button>
                                <div className="panel-body">
                                  {/* <label className="control-label">
                                    Race options:{" "}
                                  </label>{" "} */}
                                  <div
                                    {...(!state.enrollData.sexualorientation && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    {getSexualOrientationText(state.enrollData.sexualorientation)}
                                  </div>  
                                  <div
                                    {...(state.enrollData.sexualorientation != "4" && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    {state.enrollData.sexualorientationtext}
                                  </div> 

                                </div>
                                
                              </div>  

                            </div>



                              <Button
                                className="
                            webform-button--previous
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                                onClick={handlePrevious}
                                //disabled={p1}
                                {...(p1 && {
                                  style: { display: "none" },
                                })}
                              >
                                Previous
                              </Button>

                              <Button
                                className="
                                  webform-button--next
                                  button
                                  js-form-submit
                                  form-submit
                                  btn-default btn"
                                onClick={handleNextClick}
                                disabled={isProcessing}
                                {...(isProcessing && {
                                  style: { color: "black" },
                                })}
                              >
                                {isProcessing ? (
                                  <div>
                                    <span
                                      className="spinner-border pmd-spinner spinner-border-sm text-light mr-2"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    Processing...
                                  </div>
                                ) : (
                                  "Send Now"
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewEnrollment_2025;
