import { osVersion,
    osName,
    fullBrowserVersion,
    browserVersion,
    browserName,
    mobileVendor,
    mobileModel,
    engineName,
    engineVersion,
    getUA,
    deviceType} from "react-device-detect";

export function getTelemetry () {

        let data = {osVersion,
        osName,
        fullBrowserVersion,
        browserVersion,
        browserName,
        mobileVendor,
        mobileModel,
        engineName,
        engineVersion,
        getUA,
        deviceType};

        // convert to pipe delimited string of names and values
        let result = Object.entries(data)
        .map(([key, value]) => `${key}:${value}`)
        .join('|')
        .substring(0, 500); // limit to 500 characters

        return result;

}